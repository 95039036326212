@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.equipment-categories {
    display: block;
    padding-bottom: getRem(48);

    &-header {
        @extend .table-base-header-type-1;

        &-button {
            @extend .table-base-header-type-1-button;
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;

            min-width: getRem(700);

            &-first-column {
                padding-left: getRem(56);
                color: $contentSupporting;
                cursor: pointer;
                width: getRem(500) !important;
            }

            &-row {
                td:last-child {
                    width: getRem(150) !important;
                }
            }

            th:first-child {
                padding-left: getRem(56);
            }
        }
    }
}

.create-update-equipment-categories-popup-label {
    color: $contentSupporting;
    margin-left: getRem(10);
    margin-top: 0;
}


.equipment-category-react-select {

    &__control {
        box-shadow: none !important;
        cursor: pointer !important;
        border-radius: getRem(80) !important;
        border-color: $background !important;
    }

    &__indicator {
        color: $secondary !important;
        transform: scale(1.5) !important;
        padding: 0 !important;
        height: getRem(15);
        width: getRem(15);
        svg {
            height: auto;
        }
    }

    &__indicator:hover {
        color: $backgroundControls !important;
    }

    &__menu {
        right: getRem(0);
        min-width: max-content;
        border-radius: getRem(8) !important;
        box-shadow: $shadowInfoCard !important;
        margin-top: 0 !important;
    }

    &__option {
        @extend .t-s4;
        color: $contentSupporting !important;
        font-weight: 400 !important;
        background-color: $background !important;

        &--is-selected {
            @extend .t-s3;
            color: $contentHighlightPrimary !important;
        }
    }

    &__option:hover {
        background-color: $backgroundControls !important;
    }
}
