@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.newcoins-balance {
    padding-bottom: getRem(48);

    &-header {
        margin-top: getRem(12);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: getRem(24);
        justify-content: right;
        padding-top: getRem(24);
        padding-bottom: getRem(24);
        padding-left: getRem(16);
        padding-right: getRem(16);

        @include breakpoint(mobile_landscape) {
            padding-left: getRem(25);
            padding-right: getRem(25);
        }

        @include breakpoint(tablet) {
            align-items: flex-start;
        }

        @include breakpoint(desktop) {
            padding-top: getRem(14);
            gap: 0;
            justify-content: space-between;
            flex-direction: row;
        }

        div {
            display: flex;
            align-items: center;
        }

        &-settings-label {
            color: $contentAccessory;
            text-decoration: underline;
            margin-right: getRem(40);
            cursor: pointer;
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;

            td:first-child {
                min-width: getRem(48) !important;
                width: getRem(48) !important;
            }

            th:nth-child(2) {
                width: getRem(686)
            }

            &-checkbox {
                min-width: 0 !important;
                position: relative;
                left: getRem(32);
                bottom: getRem(8);
                margin-right: 0;
            }

            &-available-span {
                color: $secondary;
                svg {
                    margin-top: getRem(-3);
                    margin-left: getRem(2);
                }
            }

            &-spent-span {
                color: $contentError;
                svg {
                    margin-top: getRem(-3);
                    margin-left: getRem(2);
                }
            }

            &-action {
                color: $secondary;
                text-decoration: underline;
                cursor: pointer;
            }

            &-row {
                cursor: pointer;
            }
        }
    }

    &-popup {
        &-give {
            display: flex;
            justify-content: left;
            align-items: center;

            &-image {
                margin-bottom: getRem(4);
                margin-top: getRem(4);
                border-radius: getRem(4);
                margin-right: getRem(16);
                width: getRem(32) !important;
                height: getRem(32) !important;
            }

            &-username {
                color: $contentSupporting;
            }

            &-sign {
                background: none;
                color: $contentAccessory;
                position: absolute;
                margin-top: getRem(33);
                margin-left: getRem(13);
            }
        }
    }
}
