@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.salary-history {
    display: block;
    padding-bottom: getRem(48);

    &-info-icon {
        width: getRem(13.077) !important;
        height: getRem(13.077) !important;
        padding-bottom: getRem(1);
        margin-right: getRem(2);
        color: $contentError;
        cursor: pointer;
    }

    &-header {
        @extend .table-base-header-type-1;
        margin-top: getRem(30);
        margin-bottom: getRem(30) !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        &-user {
            justify-content: center;
            display: flex;
            align-items: center;
            text-transform: capitalize;

            img {
                margin-right: getRem(16);
                width: getRem(48);
                height: getRem(48);
                border-radius: getRem(4);

                @include breakpoint(mobile){
                    width: getRem(32);
                    height: getRem(32);
                    margin-right: getRem(8);
                }

                @include breakpoint(tablet_landscape){
                    width: getRem(48);
                    height: getRem(48);
                    margin-right: getRem(16);
                }
            }

            span {
                display: inline-block;
                width: getRem(110);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: $contentSupporting;

                @include breakpoint(mobile_landscape) {
                    width: getRem(350);
                }

                @include breakpoint(tablet_landscape) {
                    width: auto;
                }
            }
        }

        &-button {
            display: flex;
            justify-content: right;
            padding-top: 0;
        }

        &-draft {
            display: flex;
            width: auto;
            color: $contentActionPrimary;
            padding-right: getRem(54);
            flex-direction: column;
            justify-content: flex-end;
            text-decoration: underline;
        
            img {
                position: relative;
                top: getRem(-12);
                right: getRem(-6);
                width: getRem(8);
                height: getRem(9);
            }
        }

        &-span {
            display: block;
            position: absolute;
            top: getRem(-52);
        }
    }

    &-payment-type {
        display: block;
        text-align: center;
        position: absolute;
        top: getRem(-20);
        left: getRem(16);
        border-radius: getRem(3);
        height: getRem(16);
        font-size: getRem(10);
        padding-top: getRem(2.5);

        &-primary {
            @extend .salary-history-payment-type;
            width: getRem(52);
            color: $onSecondary;
            background: $secondary;
        
            &-ext {
                @extend .salary-history-payment-type-primary;
                color: $surfaceDivider;
            }
        }

        &-secondary {
            @extend .salary-history-payment-type;
            width: getRem(65);
            color: $onDisabled;
            background: $border;

            &-ext {
                @extend .salary-history-payment-type-secondary;
                color: $surfaceDivider;
            }
        }
    }
    
    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;
            padding-top: getRem(24);
            padding-left: getRem(24) !important;
            padding-right: getRem(24) !important;

            &-first-column {
                position: relative;
                padding-left: getRem(24);
                color: $contentSupporting;

                .arrow {
                    width: getRem(26);
                    height: getRem(16);
                    margin-left: getRem(-5);
                    color: $secondary;
                    cursor: pointer;
                }
            }

            .cell-limited-text {
                width: getRem(120);
            }

            > tbody > tr > td {
                > td:first-child {
                    min-width: getRem(220) !important;
                }

                > td:nth-child(2) {
                    min-width: getRem(170) !important;
                }
    
                > td:nth-child(3) {
                    min-width: getRem(150) !important;
                }
    
                > td:nth-child(4) {
                    min-width: getRem(150) !important;
                }
    
                > td:nth-child(5) {
                    min-width: getRem(150) !important;
                }
    
                > td:nth-child(6) {
                    min-width: getRem(150) !important;
                }
            }
            
            tr {
                &:first-child {
                    td td {
                        position: relative;
                    }
                }

                td td {
                    box-shadow: none !important;
                }
            }
            
            &-row {
                td {
                    color: $contentAccessory;
                }

                &-yellow {
                    background: $backgroundTableRowHiglight !important;                    
                }

                &-white{
                    background: $background;
                }

            }

            &-projects-cell {
                display: flex;
                gap: getRem(4);
                justify-content: space-between;
                flex-direction: row;
                width: getRem(130) !important;
                align-items: center;
            }
        }

    }
}

.update-salary-popup {
    display: block;
    position: static;

    .rmdp-wrapper {
        overflow: auto;
        max-height: 48vh;
    }
}
.salary-error-message {
    max-width: getRem(280) !important;
    color: $backgroundError;
}

.salary-history-overtimes {
    display: none;
    width: calc(100% - getRem(100));
    margin-left: getRem(100);
    margin-bottom: getRem(29);

    &-show {
        display: block;
    }

    &-rotate {
        transform: scaleY(-1);
        transition: 0;
    }

    tr {
        border-top: getRem(1) solid $surfaceDivider;
        height: getRem(56);

        &:last-child {
            border-bottom: getRem(1) solid $surfaceDivider;
        }
    }

    td:first-child {
        width: getRem(110) !important;
        max-width: getRem(110) !important;
        padding-left: getRem(32);
        color: $contentSupporting;
    }

    td:last-child {
        width: 100%;
        text-align: right;

        @include breakpoint(desktop) {
            padding-right: getRem(13) !important;
        }
    }

    &-inactive {
        color: $contentAccessory;
    }
}

.bonus-history {
    display: block;
    padding-bottom: getRem(48);

    &-table {
        @extend .table-base;
    }
    &-table-title {
        padding: 0 getRem(25);
        font-size: getRem(18);
        font-weight: 600;
    }

    &-header {
        @extend .table-base-header-type-1;
        margin-top: getRem(30);
        margin-bottom: getRem(30) !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;


        &-user {
            justify-content: center;
            display: flex;
            align-items: center;
            text-transform: capitalize;

            img {
                margin-right: getRem(16);
                width: getRem(48);
                height: getRem(48);
                border-radius: getRem(4);
            }

            span {
                display: inline-block;
                width: getRem(110);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: $contentSupporting;

                @include breakpoint(mobile_landscape) {
                    width: getRem(350);
                }

                @include breakpoint(tablet_landscape) {
                    width: auto;
                }
            }
        }

        &-button {
            display: flex;
            justify-content: right;
            padding-top: 0;

            button {
                min-width: getRem(183);
            }
        }
        &-span {
            display: block;
            position: absolute;
            top: getRem(-52);
        }
        &-arrow {
            position: relative;
            top: getRem(5);
            display: flex;
            align-items: center;
            margin-right: getRem(20);
            font-size: getRem(14);
            font-weight: 600;
            text-decoration: underline;
            color: $contentAccessory;

            @include breakpoint(desktop) {
                margin-right: getRem(55);
            }
        }
    }

    &-bonus-type {
        display: block;
        text-align: center;
        position: absolute;
        top: getRem(-3);
        left: getRem(16);
        border-radius: getRem(3);
        height: getRem(18);
        font-size: getRem(10);
        font-weight: bolder;
        padding-top: getRem(4);
        width: getRem(90);
        color: $onSecondary;
        background: $backgroundSuccess;

    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;
            padding-top: getRem(24);

            &-first-column {
                position: relative;
                padding-left: getRem(24);
                color: $contentSupporting;
            }
            &-last-column {
                text-align: end;
            }
        }
    }
}


.bonuses-card {
    width: getRem(375);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-date-range{
        display: flex;
        justify-content: space-between;
        text-transform: lowercase;
        font-weight: 600;
        font-size: getRem(10);
        color: $contentAccessory

    }

    &-reason {
        text-transform: none;
        line-height: getRem(16.8);
        text-overflow: ellipsis;
        display: contents;
        overflow-wrap: break-word;
        white-space: pre-line;
        flex-wrap: wrap;
        font-weight: 400;
        font-size: getRem(14);
        max-width: getRem(310);
        margin-right: getRem(15);
        padding-top: getRem(6);

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &-gray {
        text-transform: none;
        color: $contentAccessory;
        line-height: getRem(16.8);
        text-overflow: ellipsis;
        display: contents;
        overflow-wrap: break-word;
        white-space: pre-line;
        flex-wrap: wrap;
        font-weight: 400;
        font-size: getRem(14);
        max-width: getRem(310);
        margin-right: getRem(15);
        padding-top: getRem(6);

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }


    &-text-title {
        gap: getRem(80);
        display: flex;
        justify-content: space-between;
        margin-top: getRem(6);
        font-size: getRem(14);
        font-weight: 600;

        &-gray {
            color: $contentAccessory
        }
        &-action {
            font-weight: 400;
            display: flex;
            text-transform: none;
        }

    }

    &-content {
        @include breakpoint(mobile_landscape) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include breakpoint(tablet_landscape) {
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            align-items: unset;
        }

        &-info {
            flex-wrap: wrap;
            @include breakpoint(desktop) {
                display: flex;
                min-width: getRem(530);
            }

            &-card {
                margin-top: getRem(5);
                background-color: $surface;
                margin-left: getRem(26);
                box-shadow: $shadowInfoCard;
                border-radius: getRem(8);
                height: getRem(173);
                padding-top: getRem(16);
                padding-left: getRem(24);
                margin-bottom: getRem(24);
                min-width: max-content;
                max-width: getRem(310);

                &-improvements {
                    width: getRem(25);
                    height: getRem(25);
                    position: absolute;
                    margin-left: getRem(150);
                }

                &-link {
                    font-weight: 600;
                    font-size: getRem(30);
                    margin-top: getRem(8);
                    margin-bottom: getRem(2);
                    color: $contentActionPrimary;

                    &:hover {
                        color: $contentActionPrimary;
                    }
                }
                &-gray {
                    font-weight: 600;
                    font-size: getRem(30);
                    margin-top: getRem(8);
                    margin-bottom: getRem(2);
                    color: $contentAccessory;

                    &:hover {
                        color: $contentActionPrimary;
                    }
                }
            }
        }
    }
}


.bonus-popup-content {
    &-range {
        &-label {
            margin: 0;
            padding-left: getRem(10);
            padding-bottom: getRem(4);
            color: $contentSupporting;
        }

        &-chips {
            width: 100%;
            border: getRem(0.5) solid $contentAccessory;
            border-radius: getRem(8);
            overflow: hidden;
            margin-bottom: getRem(10);

            &-selected{
                background: $backgroundControls !important;
                &-disabled {
                    background-color: $surfaceDivider !important;
                }
            }

            button {
                align-items: center;
                width: 50%;
                padding: getRem(12) 0;
                border: none;
                cursor: pointer;
                border-radius: 0;
                margin: 0;
                border-right: getRem(0.5) solid $contentAccessory;
                background: $background;
            }
            button:last-child {
                border-right: none;
            }
            > button:disabled {
                pointer-events: none;
            }
        }
    }
}

.bonus-popup-amount {
    display: flex;
    gap: getRem(10);
}

.bonus-popup-delete-link{
    justify-content: center;
    text-align: center;
}

.payments {
    &-tab {
        position: fixed;
        width: 100%;
        z-index: 200;
        display: flex;
        background-color: $background;
        box-shadow: 0 10px 10px -10px rgb(0 0 0 / 15%);
        padding-bottom: getRem(10);

        &-btn {
            width: getRem(75);
            margin-left: getRem(20);
            font-weight: 400;
            background-color: $background;
            color: $contentSupporting;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: inherit;

            &-selected {
                width: getRem(75);
                padding: 0;
                margin-left: getRem(20);
                font-weight: 600;
                border: none;
                background-color: $background;
                color: $contentSupporting;
                text-decoration: underline;
                text-decoration-color: $secondary;
            }
        }

        &-active {
            z-index: 1000;
            pointer-events: auto;
        }
    }

    &-barrier {
        position: relative;
        height: getRem(20);
    }
}

.delete-bonus-popup-body {
    text-align: center;
    margin-bottom: getRem(15);
}

.delete-permanently-finish-popup-body {
    text-align: center;
    margin-bottom: getRem(15);
    width: max-content;
}

.projects-conflict-popup {
    display: inline-flex;
    padding: getRem(40) getRem(56);
    flex-direction: column;
    align-items: center;
    gap: getRem(32);
    border-radius: getRem(8);
    background-color: $surface;
    box-shadow: $shadowInfoDialog;

    @include breakpoint(mobile) {
        padding: getRem(50) getRem(28);
    }

    @include breakpoint(tablet_landscape) {
        padding: getRem(40) getRem(56);
    }

     &-body {
         margin-bottom: getRem(10);
         color: $contentSupporting;
         text-align: center;
         display: flex;
         justify-content: center;
         width: getRem(400);

         @include breakpoint(mobile) {
             width: getRem(244);
         }

         @include breakpoint(tablet_landscape) {
             width: getRem(400);
         }
     }

    &-title {
        text-align: center !important;
    }
}

.salary-ended {
    color: $contentAccessory;
}

.delete-project-warning-popup {
    display: inline-flex;
    padding: getRem(40) getRem(56);
    flex-direction: column;
    align-items: center;
    border-radius: getRem(8);
    background-color: $surface;
    box-shadow: $shadowInfoDialog;

    @include breakpoint(mobile) {
        padding: getRem(30) getRem(38);
    }

    @include breakpoint(tablet_landscape) {
        margin: auto;
    }


    &-body {
        color: $contentSupporting;
        margin-top: getRem(32);
        text-align: center;
        width: getRem(384);

        @include breakpoint(mobile) {
            width: getRem(244);
        }

        @include breakpoint(tablet_landscape) {
            width: getRem(384);
        }
    }
}

.newcoins-history {
    display: block;
    padding-bottom: getRem(48);

    &-table {
        @extend .table-base;

        &-empty-div {
            position: absolute;
            left: 45%;
        }
    }

    &-header {
        @extend .table-base-header-type-1;
        margin-top: getRem(30);
        margin-bottom: getRem(30) !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;


        &-user {
            justify-content: center;
            display: flex;
            align-items: center;
            text-transform: capitalize;

            img {
                margin-right: getRem(16);
                width: getRem(48);
                height: getRem(48);
                border-radius: getRem(4);
            }

            span {
                display: inline-block;
                width: getRem(110);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: $contentSupporting;

                @include breakpoint(mobile_landscape) {
                    width: getRem(350);
                }

                @include breakpoint(tablet_landscape) {
                    width: auto;
                }
            }
        }

        &-button {
            display: flex;
            justify-content: right;
            align-items: center;
            padding-top: 0;

            @include breakpoint(mobile_landscape) {
                gap: getRem(24);
                margin-top: getRem(12);
            }

            @include breakpoint(desktop) {
                gap: 0;
                margin-top: 0;
            }

            button {
                min-width: getRem(123);

                @include breakpoint(desktop){
                    min-width: getRem(183);
                    margin-left: getRem(24);
                }
            }
        }

        &-balance {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $contentSupporting;
            gap: getRem(5);

            svg {
                margin-bottom: getRem(3);
                width: getRem(16);
                height: getRem(16);
            }
        }
    }

    &-received {
        color: $contentSuccess;
    }

    &-withdrawn {
        color: $contentError;
    }

    &-pending {
        display: flex;
        padding: getRem(3) getRem(8);
        justify-content: center;
        align-items: center;
        gap: getRem(16);
        position: absolute;
        left: getRem(16);
        top: getRem(-4);
        background: $disabled;
        border-radius: getRem(3);
        color: $onSecondary;
    }

    &-content {
        display: flex;
        justify-content: center;

        &-cards {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: getRem(24);
            margin-bottom: getRem(32);

            @include breakpoint(mobile_landscape) {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: getRem(12);
            }

            @include breakpoint(tablet) {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                gap: getRem(12);
                margin-bottom: getRem(32);
                margin-left: getRem(24);
            }

            @include breakpoint(desktop) {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                gap: 0;
                margin-bottom: getRem(32);
                margin-left: 0;
            }
        }

        &-table {
            @extend .table-base;
            padding-top: getRem(24);

            &-label {
                color: $contentLeading;
                padding-left: getRem(24)
            }

            &-first-column {
                position: relative;
                padding-left: getRem(24);
                width: getRem(240);
                color: $contentSupporting;

                svg {
                    width: getRem(16);
                    height: getRem(16);
                    margin-bottom: getRem(2.5);
                }
            }
            &-last-column {
                width: getRem(603);
            }
        }
    }
}

.autogiven-newcoins-card {
    width: getRem(324);
    background: $background;
    height: getRem(173);
    box-shadow: $shadowInfoCard;
    border-radius: getRem(8);
    padding: getRem(18) getRem(20) getRem(18) getRem(20);
    position: relative;

    @include breakpoint(desktop) {
        margin-left: getRem(24);
        width: getRem(424);
    }

    &-slider {
        position: absolute;
        height: getRem(30) !important;
        width: getRem(48) !important;
        margin-bottom: getRem(10);
        margin-top: getRem(2);
        right: getRem(16);
        top: getRem(20);
        color: $secondary;
        cursor: pointer;

        &-inactive {
            @extend .autogiven-newcoins-card-slider;
            color: $disabled;
        }
    }

    &-body {
        margin-top: getRem(9);
    }

    &-amount {
        margin-bottom: getRem(15);
        margin-top: getRem(8);
        display: flex;
        justify-content: left;
        align-items: center;
        gap: getRem(8);

        &-number {
            color: $secondary;
        }

        svg {
            width: getRem(24);
            height: getRem(24);
        }
    }

    &-reason {
        display: flex;
        flex-direction: column;
        gap: getRem(4);
    }
}
