@import '../../styles/index.scss';

.profile {
    width: getRem(375);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(mobile_landscape) {
        width: auto;
    }

    @include breakpoint(desktop) {
        align-items: flex-start;
        flex-direction: row;
    }

    &-font {
        &-leading {
            color: $contentLeading;
        }

        &-supporting {
            color: $contentSupporting;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(tablet_landscape) {
            width: 100%;
        }

        @include breakpoint(desktop) {
            width: getRem(988);
        }
    }

    &-user {
        position: relative;
        width: getRem(343);
        box-shadow: $shadowInfoCard;
        border-radius: getRem(8);
        margin-top: getRem(43);
        background: $background;
        margin-bottom: getRem(32);

        @include breakpoint(mobile_landscape) {
            width: -webkit-fill-available;
            width: -moz-available;
            margin-left: getRem(24);
            margin-right: getRem(24);
            display: flex;
            justify-content: space-between;
        }

        @include breakpoint(desktop) {
            width: getRem(906);
            margin-top: getRem(32);
            margin-bottom: getRem(24);
            margin-left: getRem(40);
            margin-right: getRem(40);
            padding-top: getRem(8);
            padding-bottom: getRem(8);
        }

        &-avatar {
            display: flex;
            flex-direction: column;
            align-items: center;
 
            @include breakpoint(mobile_landscape) {
                flex-direction: row;
            }

            &-wrapper {
                display: contents;
            }

            img {
                margin-top: getRem(-26);
                width: getRem(128);
                height: getRem(128);
                border-radius: getRem(16);
                object-fit: cover;
                margin-bottom: getRem(16);
                
                @include breakpoint(mobile_landscape) {
                    margin: 0 getRem(16);
                }
                
                @include breakpoint(desktop) {
                    width: getRem(88);
                    height: getRem(88);
                }
            }
            &-upload {
                z-index: 2;
                position: absolute;
                visibility: visible;
                width: 32px;
                height: 32px;
                border: none;
                background-image: url("../../assets/images/pencil.svg") !important;
                margin-left: getRem(115);
                margin-top: getRem(-35);

                @include breakpoint(mobile_landscape) {
                    margin-left: getRem(125);
                    margin-top: getRem(-115);
                }
                @include breakpoint(desktop) {
                    margin-left: getRem(85);
                    margin-top: getRem(-75);
                }

                &-hidden {
                    @extend .profile-user-avatar-upload;
                    visibility: hidden;
                }
            }

            &-name {
                text-align: center;

                @include breakpoint(mobile_landscape) {
                    display: flex;
                    flex-direction: column;
                    text-align: initial;

                }

                @include breakpoint(desktop) {
                    text-align: left;
                    position: absolute;
                    margin-left: getRem(120);
                    max-width: getRem(280);
                
                    span {
                        line-height: getRem(17);
                    }

                    h3 {
                        margin-bottom: getRem(0);
                        line-height: getRem(27);
                    }
                }
            }

        }
        &-info {
            padding: getRem(48) getRem(32) getRem(32);
            
            @include breakpoint(desktop) {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0;
                margin-right: getRem(48);
                gap: getRem(24);
            }

            &-email {
                cursor: pointer;
                
                @include breakpoint(desktop) {
                    width: getRem(190);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            div {
                display: flex;
                flex-direction: column;
                gap: getRem(2);
                height: min-content;
                padding-top: getRem(5);
            }

            div:nth-child(1), div:nth-child(2) {
                @include breakpoint(desktop) {
                    width: max-content;
                }
            }

            div:nth-child(3) {
                word-break: break-all;

                @include breakpoint(mobile_landscape) {
                    max-width: getRem(240);
                }

                @include breakpoint(desktop) {
                    width: getRem(190);
                }
            }
        }

        &-menu {
            position: absolute;
            top: getRem(14);
            right: getRem(12);

            .kebab-icon {
                cursor: pointer;
                width: getRem(24);
                height: getRem(24);
                color: $secondary;
            }
        }
    }

    &-info {
        @include breakpoint(mobile_landscape) {
            display: flex;
            flex-wrap: wrap;
            margin-left: getRem(35);
            margin-right: getRem(35);
            column-gap: getRem(24);
            justify-content: flex-start;
        }

        @include breakpoint(tablet) {
            margin-left: getRem(42);
            margin-right: getRem(42);
            column-gap: getRem(42);
        }

        @include breakpoint(tablet_landscape) {
            width: -webkit-fill-available;
            margin-left: getRem(24);
            margin-right: getRem(24);
            column-gap: getRem(24);
        }

        @include breakpoint(desktop) {
            margin-left: getRem(40);
            margin-right: getRem(40);
        }

        &-card {
            position: relative;
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            border-radius: getRem(8);
            width: getRem(344);
            height: getRem(136);
            padding-top: getRem(24);
            padding-left: getRem(24);
            padding-right: getRem(24);
            margin-bottom: getRem(24);
            border: 1px solid transparent;

            &-custom-hover:hover{
                background: $backgroundControls;
                cursor: pointer;
            }
            
            @include breakpoint(tablet) {
                width: getRem(264);
            }

            @include breakpoint(desktop) {
                width: getRem(286);
            }

            h1 {
                color: $contentHighlightPrimary;
            }

            &-links {
                display: inline-flex;
                align-items: baseline;
                gap: getRem(24);
                position: absolute;
                top: 0;
                right: 0;

                span, a {
                    text-decoration: underline;
                    color: $contentActionPrimary;
                    cursor: pointer;
                    
                    &:hover {
                        color: $contentActionPrimary;
                    }
                }
            }

            &-improvements {
                width: getRem(18);
                height: getRem(18);
                position: absolute;
                right: getRem(16) ;
                top: getRem(16);
                color: $switcherCircleOn;
            }
            &-icon {
                color: $switcherCircleOn;
            }
            &-pen-icon {
                width: getRem(18) !important;
                height: getRem(18) !important;
                position: absolute;
                right: getRem(16);
                top: getRem(16);
                color: $secondary;
            }
            &-changed {
                position: absolute;
                margin-top: getRem(-16);
                color: $contentAccessory;
            }
        }

        &-vacation {
            @extend .t-s4;

            &-wrapper {
                position: absolute;
                bottom: getRem(13);
            }

            &-period {
                color: $contentAccessory;
                padding-right: getRem(4);
            }

            &-approved {
                @extend .profile-info-vacation;
                color: $contentSuccess;                
            }

            &-in-review {
                @extend .profile-info-vacation;
                color: $contentHighlightPrimary;
            }

            &-declined {
                @extend .profile-info-vacation;
                color: $contentError;
            }
        }

        &-salary {
            &-row {
                color: $contentHighlightPrimary;
                margin-bottom: getRem(3);
            }
        }

        &-newcoins {
            svg {
                width: getRem(24);
                height: getRem(24);
                margin-bottom: getRem(5);
            }
        }
    }

    &-right {
        display: flex;
        flex-direction: column;
        gap: getRem(24);
        width: 100%;
        padding-top: getRem(8);
        padding-bottom: getRem(30);
        padding-left: getRem(16);
        padding-right: getRem(16);

        @include breakpoint(desktop) {
            padding-right: getRem(26);
            padding-left: getRem(0);
            padding-top: getRem(32);
        
        }

        &-useful-info {
            position: relative;
            display: flex;
            width: 100%;
            padding: getRem(16) getRem(15) getRem(16) getRem(24);
            flex-direction: column;
            align-items: flex-start;
            gap: getRem(10);
            border-radius: getRem(8);
            background-color: $surface;
            box-shadow: $shadowInfoCard;

            &-title {
                cursor: pointer;
                color: $contentLeading;
                justify-content: space-between;
                width: getRem(302);
                display: flex;
                flex-direction: row;
                align-items: center;

                .arrow-down-icon {
                    color: $secondary !important;
                    width: getRem(24) !important;
                    height: getRem(24) !important;
                    margin-top: getRem(-4);
                    position: relative;
                    cursor: pointer;
                }

                .arrow-down-icon.expanded {
                    transform: rotate(180deg);
                }
            }

            &-body {
                margin-top: getRem(5);
                margin-bottom: getRem(12);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: getRem(8);
                align-self: stretch;

                span {
                    color: $contentSupporting;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                        color: $contentHighlightPrimary;
                    }
                }
            }
        }

        &-roles {
            position: relative;
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            border-radius: getRem(8);
            padding: getRem(24);

            @include breakpoint(desktop) {
                height: getRem(104);
            }

            > span {
                display: block;
                margin-bottom: getRem(16);
            }

            &-content {
                display: inline-block;
                color: $contentSupporting;
            }
        }

        &-projects {
            position: relative;
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            border-radius: getRem(8);
            min-height: getRem(136);
 
            padding: getRem(24);
            padding-right: getRem(16);

            > span {
                display: inline-block;
                margin-bottom: getRem(16);
            }

            &-content {
                display: flex;
                flex-direction: column;
                gap: getRem(8);
            }

            &-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &-name {
                    color: $contentSupporting;
                    width: getRem(205);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                &-date {
                    color: $contentAccessory;
                }
            }
        }

        &-equipment {
            overflow-y: auto;
            position: relative;
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            border-radius: getRem(8);
            padding: getRem(24);

            @include breakpoint(desktop) {
                min-height: getRem(296);
            }

            &::-webkit-scrollbar {
                @extend .custom-scroll-webkit-scrollbar;
            }

            &::-webkit-scrollbar-track {
                @extend .custom-scroll-webkit-scrollbar-track;
            }

            &::-webkit-scrollbar-thumb {
                @extend .custom-scroll-webkit-scrollbar-thumb;
            }

            > span {
                display: inline-block;
                margin-bottom: getRem(24);
            }

            .profile-equipment {
                &-content {
                    display: flex;
                    flex-direction: column;
                    gap: getRem(16);    
                }

                &-box {
                    display: flex;
                    flex-direction: column;
                    gap: getRem(2);
                }
                
                &-title {
                    word-wrap: break-word;
                    max-width: getRem(250);
                    color: $contentSupporting;
                }

                &-serial-num {
                    color: $contentAccessory;
                }
            }

            .empty-card {
                margin-top: getRem(97);
            }
        }

        &-onboarding {
            position: relative;
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            border-radius: getRem(8);
            min-height: getRem(136);

            padding: getRem(24);
            padding-right: getRem(15);

            &-header {
                display: flex;
                align-items: center;
                gap: getRem(8);
            }

            &-content {
                padding-bottom: getRem(12);
                padding-top: getRem(16);

                &-step {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    align-self: stretch;
                    margin-bottom: getRem(6) !important;
                }
            }

            &-in-review-tag {
                display: flex;
                padding: getRem(2) getRem(9);
                align-items: center;
                gap: getRem(10);
                position: absolute;
                right: getRem(16);
                top: getRem(-2);
                border-radius: getRem(3);
                background: $secondary;
                color: $onSecondary;

                &-mobile {
                    background: $secondary;
                    color: $onSecondary;
                    align-items: center;
                    padding: getRem(2) getRem(9);
                    border-radius: getRem(3);
                }
            }

            &-manager-actions {
                position: absolute;
                top:0;
                right:0;
                margin-top: getRem(24);
                margin-right: getRem(-8);
                display: flex;
                align-items: flex-start;
            }

            &-info-icon {
                width: getRem(16) !important;
                height: getRem(16) !important;
                margin-bottom: getRem(1);
                color: $secondary;
            }

            &-button {
                display: flex;
                padding: getRem(8) getRem(24);
                justify-content: center;
                align-items: center;
                gap: getRem(10);
                border-radius: getRem(8);
                background: $secondary;
                border: none;
                color: $onSecondary;
                cursor: pointer;

                &:hover {
                    background: $secondaryVariant;
                }
            }
        }
    }

    &-perf-review {
        background: $background;
        border-radius: getRem(8);
        letter-spacing: getRem(1);
        outline: none;
        overflow-x: hidden;
        overflow-y: overlay;
        padding: getRem(48) getRem(16) getRem(32) getRem(16);
        width: getRem(340);
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::-webkit-scrollbar {
            @extend .custom-scroll-webkit-scrollbar;
        }
          
        &::-webkit-scrollbar-track {
            @extend .custom-scroll-webkit-scrollbar-track;
        }
          
        &::-webkit-scrollbar-thumb {
            @extend .custom-scroll-webkit-scrollbar-thumb;
        }

        @include breakpoint(tablet) {
            padding: getRem(56) getRem(40);       
            width: getRem(612);
            height: getRem(616);
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: getRem(30);
            margin-bottom: getRem(30);
            word-wrap: break-word;

            &-title {
                &-close {
                    text-align: end;
                    margin-top: getRem(-25);   

                    @include breakpoint(tablet){
                        margin-right: getRem(-10);
                    } 
                    svg {
                        color: $disabled;
                        &:hover {
                            color: $secondary;
                            cursor: pointer;
                        }
                    }
                }

                &-head {
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;

                    svg {
                        color: $secondary;
                        height: getRem(30);
                        width: getRem(30);
                        &:hover {
                            color: $secondaryVariant;
                            cursor: pointer;
                        }
                    }

                    span {
                        width: getRem(225);
                        @include breakpoint(tablet) {
                            width: getRem(320);
                        }
                    }
                }
            }

            &-date {
                text-align: center;
                color: $contentAccessory;
                margin-top: getRem(-25);
            }

            &-goals, &-summary {
                display: flex;
                flex-direction: column;
                gap: getRem(16);
                width: 100%;

                &-content {
                    color: $contentSupporting;
                    h2 {
                        font-size: getRem(18);
                        margin-bottom: getRem(0) !important;
                    }

                    p, span, li, ul, ol {
                        font-size: getRem(14);
                        margin-bottom: getRem(0);
                    }
                    li {
                        &::marker {
                            font-weight: 600 !important;
                        }
                    }

                    a {
                        color: $textEditorLink;
                        text-decoration: underline;
                    }
                }

                &-title {
                    text-align: center;
                }
            }
            
            &-placeholder {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-content: center;
                height: 50vh;
                color: $contentAccessory;

                @include breakpoint(tablet) {
                    height: getRem(350);
                }
            }
        }
    }
}

input[type="file"] {
    display: none;
}



#simple-menu {
    .MuiMenu-list div li {
        @extend .t-s4;
        color: $contentSupporting;
    
        &:hover {
            background-color: $backgroundControls;
        }
    }

    .MuiPaper-root {
        margin-top: getRem(12);
        margin-left: getRem(-7);
    }

    .MuiMenuItem-root {
        min-height: getRem(32) !important;
        height: getRem(32) !important;
        min-width: getRem(318) !important;
    }
}

.first-day-popup {
    .rmdp-wrapper {
        max-height: 45vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            @extend .custom-scroll-webkit-scrollbar;
        }

        &::-webkit-scrollbar-track {
            @extend .custom-scroll-webkit-scrollbar-track;
        }

        &::-webkit-scrollbar-thumb {
            @extend .custom-scroll-webkit-scrollbar-thumb;
        }
    }
}

.edit-employee-popup {
    .toolkit-dd-list-select__menu {
        max-height: 30vh;

        * {
            max-height: 30vh;
        }
    }
}

.edit-english-popup {
    .toolkit-dd-list-select__menu {
        max-height: getRem(100);

        * {
            max-height: getRem(100);
        }
    }
}

.improvement-popup {
    width: getRem(400) !important;
    .popup-bottom {
        padding-top: getRem(20);
    }
}

.bonus-reason {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 650px;
}

.edit-projects {
    &-popup {
        position: static;

        .custom-popup-card-content {
            @include breakpoint(mobile_landscape) {
                padding-left: getRem(57);
                padding-right: getRem(57);
            }
        }

        .toolkit-dd-list-select__menu {
            max-height: getRem(130);

            * {
                max-height: getRem(130);
            }
        }

        &-progress {
            padding-top: getRem(40);
        }

        &-box {
            &-item {
                display: flex;
                flex-direction: column;
                gap: getRem(8);
                margin-bottom: getRem(8);
            }

            > span {
                display: inline-block;
                text-align: right;
                width: 100%;
                color: $contentSupporting;
            }
        }

        &-form {
            margin-top: getRem(24);
        }
    }

    &-item {
        display: flex;
        border: getRem(0.5) solid $disabled;
        border-radius: getRem(8);
        min-height: getRem(40);
        margin: auto;
        width: 100%;

        &-value {
            width: 100%;
            display: flex;
            align-items:baseline;
            padding-left: getRem(16);
            align-items:center;
            color: $contentSupporting;
            
            span {
                max-width: getRem(200);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                @include breakpoint(mobile_landscape) {
                    max-width: getRem(240);
                }
            }
        }

        &-button {
            margin-bottom: getRem(7);
            margin-top: getRem(7);
            margin: auto;
            border-left: getRem(0.5) solid $disabled;
            width: getRem(45);
            text-align: center;
            cursor: pointer;

            .remove-icon {
                width: getRem(22);
                height: getRem(22);
                color: $secondary;
            }
        }

        &-disabled {
            background-color: $surfaceDivider;

            .edit-projects-item {
                &-button {
                    background-color: $surfaceDivider;
                    cursor: default;

                    img {
                        filter: grayscale(100%);
                    }
                }
            }
        }
    }
}

.newcoins-popup {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: getRem(313);
    max-height: getRem(520) !important;

    @include breakpoint(mobile) {
        width: getRem(313);
    }

    @include breakpoint(tablet) {
        width: max-content;
    }

    ::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
    }

    ::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
    }

    ::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
    }

    &-loading {
        display: flex;
        width: getRem(313);
        justify-content: center;
        align-items: center;

        @include breakpoint(mobile) {
            width: getRem(313);
        }

        @include breakpoint(tablet) {
            width: getRem(550);
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: getRem(303);
        margin-bottom: getRem(22);
        gap: getRem(5);

        @include breakpoint(mobile) {
            width: getRem(303);
        }

        @include breakpoint(tablet) {
            width: getRem(550);
        }

        &-info-text, &-info-balance {
            flex: 0 0 auto;
            color: $contentHighlightPrimary;

            &-inactive {
                color: $contentAccessory;
            }
        }

        &-filter {
            margin-left: auto;
        }

        svg {
            width: getRem(19);
            height: getRem(19);
            margin-top: getRem(-2);
        }
    }

    &-title {
        color: $contentLeading;
        margin-bottom: getRem(24);
    }

    &-left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: getRem(22);

        @include breakpoint(mobile) {
            width: getRem(285);
        }

        @include breakpoint(tablet) {
            width: getRem(524);
        }

        &-info {
            color: $contentHighlightPrimary;

            &-inactive {
                color: $contentAccessory;
            }
        }
    }

    &-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: getRem(32);

        &-head {
            margin-bottom: getRem(-40) !important;
        }

        &-row {
            color: $contentLeading;
            position: relative !important;
            display: flex;
            gap: getRem(16);
            justify-content: left;

            @include breakpoint(mobile) {
                gap: getRem(16);
            }

            @include breakpoint(tablet) {
                gap: getRem(32);
            }

            &:last-child {
                margin-bottom: getRem(24);
            }

            &-date {
                width: getRem(55) !important;

                @include breakpoint(mobile) {
                    width: getRem(55) !important;
                }

                @include breakpoint(desktop) {
                    margin-left: 0;
                    width: getRem(80) !important;
                }
            }

            &-note {
                width: getRem(125) !important;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer;

                @include breakpoint(mobile) {
                    width: getRem(125) !important;
                }

                @include breakpoint(tablet) {
                    width: getRem(288) !important;
                }

                &-show {
                    overflow: visible;
                    white-space: normal;
                }
            }

            &-transaction {
                width: getRem(94) !important;
                text-align: right;

                @include breakpoint(mobile) {
                    width: getRem(94) !important;
                }

                @include breakpoint(tablet) {
                    width: getRem(120) !important;
                }

                &-mobile {
                    color: $contentAccessory;
                }
            }
        }

        &-divider {
            margin-top: getRem(12);
            margin-bottom: getRem(12);
            color: $contentSupporting;
            opacity: 0.2;
            width: 100%;

            &.first {
                margin-top: getRem(0);
            }
        }
    }

    &-pending {
        display: flex;
        padding: getRem(3) getRem(8);
        justify-content: center;
        align-items: center;
        gap: getRem(16);
        position: absolute;
        left: 0;
        top: 0;
        background: $disabled;
        border-radius: getRem(3);
        color: $onSecondary;
    }

    &-close {
        top: getRem(-12) !important;
        right: getRem(-4) !important;
        @include breakpoint(desktop){
            top: getRem(-16) !important;
            right: getRem(-18) !important;
        }
    }
}


.reset-input-button {
    position: absolute;
    color: $disabled;
    stroke: none !important;
    border-left: none !important;
    width: getRem(20) !important;
    height: getRem(20) !important;
    top: getRem(10);
    left: getRem(82);

    &:hover {
        color: $secondary;
        cursor: pointer;
    }
}

.popup-bottom-button-container {
    width: getRem(288) !important;

    label {
        display: inline-flex;
        text-align: center;
        align-items: center;
    }
}

.default-photo-content {
    text-align: center;

    img {
        width: getRem(255);
        border-radius: getRem(16);
        margin-bottom: getRem(24);
    }

    label {
        display: inline-flex;
        text-align: center;
        align-items: center;
    }
}

@keyframes glowing {
    30% {background-color: $backgroundControls;}
    80% {background-color: $background;}
    100% {background-color: $background;}
}

.highlight-animation {
    animation: glowing 2000ms infinite;
}

.dot-types {
    height: getRem(6);
    width: getRem(6);
    border-radius: 50%;
    display: inline-block;
    margin-right: getRem(6);
    margin-bottom: getRem(2);
}