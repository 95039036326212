@import "../../styles/index.scss";

.auth-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .auth-wrapper-content {
    flex-grow: 1;
  }

  &-logo {
    user-select: none;
    width: getRem(90);
    height: getRem(90);
    margin-top: getRem(48);
    filter: drop-shadow(getRem(0) getRem(0) getRem(100) rgba(255, 255, 255, 0.50));
    border-radius: 50%;
    box-shadow: 0 0 getRem(80) #ccc;

    @include breakpoint(mobile_landscape) {
      width: getRem(120);
      height: getRem(120);
      margin-top: getRem(80);
    }

    @include breakpoint(desktop) {
      width: getRem(150);
      height: getRem(150);
    }
  }

  &-login {
    &-title {
      font-weight: 600;
      font-size: getRem(24);
      text-align: center;
      margin-top: getRem(24);

      @include breakpoint(mobile_landscape) {
        font-size: getRem(40);
      }
    }

    &-form {
      width: getRem(112);
      margin: auto;
      min-width: getRem(300);
      min-height: getRem(205);

      > .popup-bottom {
        margin-top: getRem(15);
      }

      &-email-field, &-password-field {
          text-align: left;
          margin: getRem(0) auto;
          height: getRem(40);
          width: 100%;
          color: #010101;
          font-size: getRem(16);
          letter-spacing: getRem(1);
          background: rgba(248, 246, 246, 0.04);
          outline: none;
          border: getRem(0.5) solid $border;
          padding: getRem(12) getRem(16);
          display: block;
          border-radius: getRem(8);

          &::placeholder {
            color: $disabled;
            font-size: getRem(14);
            font-style: normal;
            line-height: normal;
          }
        }

      &-email-label, &-password-label {
        margin-top: getRem(16);
        margin-left: getRem(8);
        margin-bottom: getRem(4);
        color: $blackTransparent;
      }

      &-password-field {
        padding-right: getRem(50);
      }

      &-password {
        position: relative;

        &-eye-icon{
          color: $eyePasswordIcon !important;
        }

        &-visible {
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          height: getRem(40);
          width: getRem(24);
          position: absolute;
          top: getRem(39);
          right: getRem(12);
          cursor: pointer;
        }

        &::before {
          content: '';
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          right: getRem(46);
          top: getRem(46);
          border-left: getRem(0.001) solid $disabled;
          height: getRem(24);
        }
      }
      
      &-actions {
        margin-top: getRem(16);
        justify-content: flex-end;
        display: flex;
        align-items: center;

        .container {
          padding-left: getRem(30);
          width: 55%;
          margin-top: 0;
        }

        &-forgot-password{
          letter-spacing: getRem(0.5);
          color: $contentActionPrimary;
          text-decoration: underline;
          font-size: getRem(14);
          font-weight: lighter;
        }

        a:hover {
          color: $contentHighlightPrimary;
        }

      }

      &-bottom {
        margin-top: getRem(24);
        justify-content: center;
        display: flex;
        align-items: center;

        .container {
          padding-left: getRem(30);
          width: 55%;
          margin-top: 0;
        }

        &-return-login {
          letter-spacing: getRem(0.5);
          color: $contentActionPrimary;
          text-decoration: underline;
          font-size: getRem(14);
          font-weight: lighter;
        }

        &-return-login:hover {
          color: $contentHighlightPrimary;
        }
      }

      &-submit {
        padding-top: getRem(10);
      }

      &-google {
        padding-top: getRem(10);
        background-color: white;

        button {
          width: 100%;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: getRem(10);
        }

        img {
          width: getRem(16);
          height: getRem(16);
        }
      }

      &-divider-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: getRem(24);
        margin-bottom: getRem(8);

        .line {
          flex-grow: 1;
          max-width: 100%;
          height: getRem(1);
          background-color: $surfaceDivider;
        }

        span {
          margin: 0 getRem(8);
          font-size: getRem(14);
          color: $disabled;
          white-space: nowrap;
        }
      }

      input:-webkit-autofill {
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #030303;
        color: transparent;
      }
    }
  }

  &-return-to-login {
    margin-top: getRem(40);
  }

  &-version {
    @extend .t-s4;
    font-size: getRem(13);
    bottom: getRem(1);
    width: 100%;
    color: $blackTransparent;
    font-weight: 400;
    text-align: center;
    margin-top: getRem(64);
  }

  &-reset-pass {
    margin-top: getRem(24);
    margin-bottom: getRem(40);
    font-size: getRem(18);
    color: $blackTransparent;
    line-height: getRem(21);
    text-align: center;
  }
}
