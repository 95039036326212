@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.public-day-offs {
    display: block;
    padding-bottom: getRem(48);
    
    &-header {
        @extend .table-base-header-type-1;

        @include breakpoint(mobile_landscape) {
            margin-bottom: getRem(20);
        }

        &-user {
            padding-top: getRem(24);    
        }

        &-button {
            @extend .table-base-header-type-1-button;
        }
    }

    &-select {
        display: flex;
        justify-content: flex-end;
        padding-right: getRem(16);

        @include breakpoint(mobile_landscape) {
            padding-right: getRem(25);
        }

        &-content {
            width: max-content;
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;
            min-width: getRem(1000);

            td:first-child, th:first-child {
                padding-left: getRem(56);
                width: getRem(311);
            }

            td:nth-child(2) {
                .cell-limited-text {
                    width: fit-content;
                    max-width: getRem(350);
                }
            }

            td:last-child {
                width: getRem(140);
            }
        }
    }
}

.create-update-public-day-offs-popup {
    .rmdp-wrapper {
        overflow: auto;
        max-height: 46vh;
    }
}
