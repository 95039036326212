@import '../../styles/index.scss';


.toolkit-dd-list-year {
    &-react-select {
        &__control {
            box-shadow: none !important;
            cursor: pointer !important;
            border-radius: getRem(80) !important;
            border-color: transparent !important;
            background-color: transparent !important;
        }
    
        &__indicator {
            color: $secondary !important;
            transform: scale(1.5) !important;
            padding: 0 !important;
        }
    
        &__indicator:hover {
            color: $backgroundControls !important;
        }
    
        &__menu {
            border-radius: getRem(8) !important;
            box-shadow: $shadowDropdown !important;
            margin-top: 0 !important;
        }
    
        &__option {
            @extend .t-s4;
            color: $contentSupporting !important;
            font-weight: 400 !important;
            background-color: $background !important;
        
            &:first-child {
                margin-top: getRem(6) !important;
            }

            &:last-child {
                margin-bottom: getRem(6) !important;
            }

            &--is-selected {
                @extend .t-s3;
                color: $contentHighlightPrimary !important;
            }
        }
    
        &__option:hover {
            background-color: $backgroundControls !important;
        }
    }
}
