@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.time-records {
    display: block;
    padding-top: getRem(30);
    padding-bottom: getRem(48);
    height: fit-content;

    &-content {
        display: block;
        justify-content: center;

        &-table {
            @extend .table-base;
            
            td:first-child, th:first-child {
                padding-left: getRem(56);
            }

            td:last-child {
                width: 15%;
            }

            td:nth-child(1) {
                width: 17%;
            }

            td:nth-child(2), td:nth-child(3) {
                width: 12%;
            }

            td:nth-child(4) {
                width: 10%;
            }

            td:nth-child(6) {
                width: 12%;
            }
        }

        &-table-ext {
            @extend .time-records-content-table;

            th:first-child {
                padding-left: getRem(31);
            }

            td:first-child {
                padding-left: 0;
                width: 22%;
            }

            td:nth-child(2), td:nth-child(3) {
                width: 11%;
            }
            
            td:nth-child(4) {
                width: 16%;
            }

            td:nth-child(5) {
                width: 10%;
            }

            td:nth-child(6) {
                width: 18%;
            }

            td:last-child {
                width: 12%;
            }
        }
        &-status {
            &-logged {
                color: $disabled;
            }

            &-in-review {
                color: $contentHighlightPrimary;
            }

            &-approved {
                color: $contentSuccess;
            }

            &-declined {
                color: $contentError;
            }
            
        }

        &-actions {
            border: none;
            background: transparent;

            &:disabled {
                color: $disabled;
                cursor: text;
            }
        }
    }

    &-filters {
        display: flex;

        &-period {
            display: flex;
            align-items: center;
            column-gap: getRem(40);

            @include breakpoint(mobile_landscape) {
                column-gap: getRem(34);
            }

            &-wrapper {
                padding-left: getRem(32);
                padding-right: getRem(32);
                display: flex;
                flex-direction: column;
                gap: getRem(20);
                padding-top: getRem(20);
                overflow-x: auto;
                height: getRem(50);

                @include breakpoint(mobile_landscape) {
                    flex-direction: row;
                    padding-top: 0;
                    overflow-x: hidden;
                }
            }

            &-label {
                color: $contentAccessory !important;
            }

            &-selected {
                border-bottom: getRem(1) solid $contentActionPrimary; 
            }

            &-generated {
                width: max-content !important;
                cursor: default !important;
                text-decoration: none !important;
            }

            > span {
                color: $contentSupporting;
                white-space: nowrap;

                &:last-child {
                    padding-right: getRem(32);

                    @include breakpoint(mobile_landscape) {
                        padding-right: 0;
                    }
                }

                &:not(:first-child) {               
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            p {
                margin: auto;
            }
            .reports-menu-active {
                padding: 0 !important;
            }

            &-calendar {
                &:hover {
                    @extend .t-s3;
                    cursor: pointer;
                    text-decoration: underline $secondary;
                }
            }

            .single-date-input-wrapper {
                margin: 0;
            }
        }

        &-projects {
            display: flex;
            align-items: center;

            
            &-filter-text{
                padding-right: getRem(16);
                color: $contentAccessory !important;
                margin-right: getRem(8) !important;
            }
            button {
                margin-top: getRem(3);
            }
        }
    }

    &-projects {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-label {
            color: $contentSupporting;
        }
    }
}
