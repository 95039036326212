@import '../../styles/index.scss';

.toolkit-dd-list {
    &-wrapper {
        margin-bottom: getRem(14);
    }

    &-label {
        margin: 0;
        padding-left: getRem(10);
        padding-bottom: getRem(4);
        color: $contentSupporting;
    }

    &-select {
        &__single-value {
            color: $contentSupporting !important;
        }
        &__value-container {
            padding-left: getRem(13) !important;
            padding-right: getRem(13) !important;
        }
        &__control {
            cursor: pointer;
            display: block;
            min-height: auto !important;
            height: getRem(40) !important;
            width: 100% !important;
            box-shadow: none !important;
            cursor: pointer !important;
            border: getRem(0.5) solid $disabled !important;
            border-radius: getRem(8) !important;
        }

        &__indicators {
            height: getRem(40) !important;
            width: getRem(40) !important;

            &-wrapper {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .arrow {
                width: getRem(26);
                height: getRem(26);
                color: $secondary;

            }
        }

        &__indicator {
            color: $secondary !important;
            height: getRem(40) !important;
        }

        &__indicator-separator {
            margin: 7 !important;
            padding: 0 !important;
            background-color: $border !important;
        }

        &__dropdown-indicator {
            width: calc(getRem(39) - 1px);
            display: flex;
            justify-content: center;
            svg {
                height: getRem(40) !important;
                width: getRem(25) !important;
                transform: scale(0.8) !important;
            }
        }

        &__menu {
            border-radius: getRem(8) !important;
            box-shadow: $shadowDropdown !important;
            margin-top: 8 !important;
            overflow-y: auto !important;
            cursor: pointer !important;
            z-index: 5 !important;

            &::-webkit-scrollbar {
                @extend .custom-scroll-webkit-scrollbar;
            }

            &::-webkit-scrollbar-track {
                @extend .custom-scroll-webkit-scrollbar-track;
            }

            &::-webkit-scrollbar-thumb {
                @extend .custom-scroll-webkit-scrollbar-thumb;
            }

            ::-webkit-scrollbar {
                @extend .custom-scroll-webkit-scrollbar;
            }

            ::-webkit-scrollbar-track {
                @extend .custom-scroll-webkit-scrollbar-track;
            }

            ::-webkit-scrollbar-thumb {
                @extend .custom-scroll-webkit-scrollbar-thumb;
            }
        }

        &__option {
            cursor: pointer !important;
            @extend .t-s4;
            color: $contentSupporting !important;
            background-color: $background !important;

            &--is-selected {
                @extend .t-s3;
                color: $contentHighlightPrimary !important;
            }
        }

        &__option:hover {
            background-color: $backgroundControls !important;
        }
    }
}
