@import '../styles/index.scss';
@import '../styles/new-year-theme.scss';
@import '../styles/easter-theme.scss';


/* Containers styles */
body {
  font-family: "Gilroy", serif;
  background: $background;
}

.page-container {
  display: flex;
  background: $background;
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.toolbar {
  position: fixed;
  width: 100%;
  height: getRem(69);
  box-shadow: $shadowHeader;
  margin: 0;
  z-index: 105;
  padding-left: getRem(16);

  display: flex;
  align-items: center;
  background-color: $background;

  @include breakpoint(tablet) {
    padding-left: getRem(96);
    padding-right: getRem(20);
    padding-right: calc(getRem(40) - 12px);
  }

  &-active {
    z-index: 1000;
    pointer-events: auto;
  }

  .menu-button {
    cursor: pointer;
    padding-right: getRem(16);

    .menu-icon {
      width: getRem(24);
      height: getRem(24);
    }
  }

  .toolkit-slider {
    margin-left: calc(getRem(17) - 12px);
  }
}

.main-content {
  width: auto;
  max-width: 100vw;
  overflow: hidden;
  min-height: calc(100vh - getRem(69));
  margin-top: getRem(69);
  margin-left: 0;
  background-color: $background;
  @include breakpoint(tablet) {
    max-width: calc(100vw - getRem(70));
    margin-left: getRem(70);
  }

  &-admin {
    @extend .main-content;
    margin-left: 0;
    max-width: 100vw;
  }
}

.multi-line-cell {
  white-space: pre-line;
  word-break: break-all;
  width: getRem(270);
  padding-top: getRem(10);
  padding-bottom: getRem(10);
  padding-right: getRem(50);
}

.no-data {
  width: 100%;
  margin-top: 5%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

/* Popup styles */
.popup {
  &-content {
    display: block;
    height: fit-content;
  }

  &-bottom {
    width: 100%;
    display: flex;
    justify-content: center;

    &-button-container {
      width: getRem(276);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: getRem(24);
      padding-top: getRem(30);

      &-with-link {
        padding-top: getRem(27);
        gap: getRem(15);
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

.empty-result {
  color: $contentAccessory;
  position: inherit;
  text-align: center;
  padding-top: getRem(10);
  p {
    line-height: getRem(200);
    margin-top: getRem(-50);
    position: absolute;
    width: -webkit-fill-available;
    top: 50%;
  }
}

.empty-card {
  color: $contentAccessory;
  position: inherit;
  margin-top: getRem(25);
  text-align: center;
}

.table-category {
  background: none;
  color: $contentSupporting;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  &-selected {
    color: $contentSupporting;
    text-decoration: underline;
    text-decoration-color: $secondary;
  }
}

.cell-limited-text {
  display: block;
  width: getRem(200);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-limited-text-small-table {
  @extend .cell-limited-text;
  width: fit-content;
  max-width: getRem(150);
}

.info-popup-note {
  overflow-wrap: break-word;
  white-space: pre-line;
  color: $contentSupporting;
}


.table-box-shadow-styles {
 &-td {
  box-shadow:
  inset 0px getRem(11) getRem(8) getRem(-12) rgba(0, 0, 0, 0.15),
  inset 0px getRem(-11) getRem(8) getRem(-12) rgba(0, 0, 0, 0.15);

  @include breakpoint(mobile_landscape) {
    box-shadow: none;
  }
 }

 &-td-first-child {
  border-bottom-left-radius: getRem(8);
  border-top-left-radius: getRem(8);
  box-shadow:
      inset 0px getRem(11) getRem(8) getRem(-12) rgba(0, 0, 0, 0.15),
      inset 0px getRem(-11) getRem(8) getRem(-12) rgba(0, 0, 0, 0.15),
      inset getRem(11) 0px getRem(8) getRem(-12) rgba(0, 0, 0, 0.15);

  @include breakpoint(mobile_landscape) {
      box-shadow: none;
  }
 }

 &-td-last-child {
  border-bottom-right-radius: getRem(8);
  border-top-right-radius: getRem(8);
  box-shadow:
      inset 0px getRem(11) getRem(8) getRem(-12) rgba(0, 0, 0, 0.15),
      inset 0px getRem(-11) getRem(8) getRem(-12) rgba(0, 0, 0, 0.15),
      inset getRem(-11) 0px getRem(8) getRem(-12) rgba(0, 0, 0, 0.15); 
  
  @include breakpoint(mobile_landscape) {
      box-shadow: none;
  }
 }
}

.text-top {
  padding-left: getRem(5);
  vertical-align: text-top;
}

.sensitive-info {
  font-size: getRem(22);
}

.telegram-icon>svg{
  width: getRem(16);
  height: getRem(16);
  margin-left: getRem(8);
  padding-bottom: getRem(3);
}

.copy-icon {
  width: getRem(16);
  height: getRem(16);
  margin-left: getRem(8);
}

.aligned-tabs {
  font-variant-numeric: tabular-nums;
}


.mobile-table-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  &-item {
    display: flex;
    flex-direction: column;
    padding: getRem(24) getRem(16);
    align-items: normal;
    gap: getRem(16);
    border-radius: getRem(8);
    box-shadow: $shadowTable;
    margin: getRem(8) getRem(16) getRem(8) getRem(16);  

    &-user {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 16px;
      align-items: center;

      &-image {

        &-employee-name {
          @extend .t-s1;
        }

        img {
          width: getRem(40);
          height: getRem(40);
          border-radius: getRem(4);
        }
      }
    }

    &-general {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        white-space: nowrap;
      }

      .custom-scrollable-table-link:last-child {
        margin-right: 0;
      }

      &-note {
        display: flex;
        justify-content: space-between;

        .cell-limited-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: pre-line;
        }
      }
    }
  }
}

.clear-draft-icon {
  position: relative;
  top: getRem(-12);
  right: getRem(-6);
  width: getRem(14) !important;
  height: getRem(15) !important;
  cursor: pointer;
  color: $secondaryVariant !important;
}

.loading-div {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}