@import '../../styles/index.scss';

.flexible-popup {
    background: $background;
    width: max-content;
    border-radius: getRem(8);
    letter-spacing: getRem(1);
    outline: none;
    overflow-x: hidden;
    overflow-y: overlay;
    padding: getRem(32) getRem(16);

    @include breakpoint(tablet) {
        padding: getRem(40);
    }

    &::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
    }

    &::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
    }

    &::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
    }

}