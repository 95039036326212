@import '../../styles/index.scss';


.number-input {
    min-width: getRem(240);
    width: 100%;
    height: getRem(40);
    padding: getRem(8);
    border-radius: getRem(8);
    outline: none;
    border: getRem(0.5) solid $disabled;
}
