@import '../../styles/index.scss';


.wrapper-navs-container {
    display: block;
    width: 100%;
    height: 100vh;
    background: $background;

    @include breakpoint(tablet) {
        width: 0;
    }
}

.navs {
    position: fixed;
    z-index: 106;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);

    padding-left: 5vw;
    padding-right: 5vw;

    @include breakpoint(tablet) {
        background: $primary;
        width: getRem(70);
        padding-left: 0;
        padding-right: 0;
    }

    &-header {
        height: getRem(69);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpoint(tablet) {
            margin-bottom: 10vh;
        }
        
        @include breakpoint(tablet) {
            justify-content: center;            
        }

        &-view-as-member {
            cursor: pointer;
            text-decoration: underline;
            color: $contentActionPrimary;
            margin-left: getRem(25);

            &:hover {
                color: $contentActionPrimary;
            }
        }
        &-viewing-as {
            position: relative;
            cursor: pointer;
            color: $contentLeading;
            margin-left: getRem(25);

            &-user-image {
                height: getRem(36);
                width: getRem(36);
                margin-left: getRem(10);
                margin-right: getRem(10);
                border-radius: getRem(4);
            }

            &-exit {
                position: absolute;
                top: 0;
                right: getRem(-15);
            }
        }

        &-right-nav-span {
            display: flex;
            justify-content: right;
            align-items: center;
            margin-left: auto;
            color: $contentSupporting;
            width: getRem(400);
        }
    }

    &-content {
        height: 84vh;
        width: 100%;
        display: flex;
        flex-direction: column;

        @include breakpoint(tablet) {
            padding-bottom: 25vh;
        }

        &-menu-item {
            height: getRem(60);
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;

            display: flex;
            align-items: center;
            flex-direction: row;

            @include breakpoint(tablet) {
                height: getRem(65);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-private-mode-wrapper {
                margin-bottom: getRem(10);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-img-wrapper {
                display: flex;
                align-items: center;
                justify-content: left;
                width: fit-content;
                height: getRem(40);
                border-radius: getRem(8);

                @include breakpoint(tablet) {
                    justify-content: center;
                    width: getRem(40);

                    &:hover {
                        border: 1px solid $secondary;
                    }
                }

                &-custom-color {
                    color: $secondary;
                }
            }

            &-title {
                color: rgba(255, 255, 255, 0.8);
                font-size: getRem(18);
                font-weight: 700;
                vertical-align: middle;
                padding-left: getRem(10);

                &-private-mode {
                    color: rgba(255, 255, 255, 0.8);
                    font-size: getRem(18);
                    font-weight: 700;
                    width: max-content;
                    vertical-align: middle;
                    padding-left: getRem(10);
                }
            }
        }
    }

    &-footer {
        display: flex;
        position: absolute;
        bottom: getRem(0);
        align-items: center;
        width: 100%;

        @include breakpoint(tablet) {
            display: flex;
            flex-direction: column-reverse;
            position: absolute;
            bottom: 1vh;
            justify-content: center;
            width: 100%;
        }

        &-private-mode-mobile-icon {
            height: getRem(25);
            width: getRem(25);
            text-align: center;
        }

        &-private-mode-icon {
            height: getRem(15) !important;
            width: getRem(20) !important;
        }

        &-private-mode-slider {
            height: getRem(25) !important;
            width: getRem(55) !important;
            margin-bottom: getRem(10);
            color: $secondary;

            &-inactive {
                color: $disabled;
                height: getRem(25) !important;
                width: getRem(55) !important;
                margin-bottom: getRem(10);

            }
        }

        &-private-mode-mobile-slider {
            justify-items: center;
            margin-bottom: getRem(3);
            margin-left: getRem(145);
        }

        &-private-mode-tablet-slider {
            justify-items: center;
            margin-bottom: getRem(3);
            margin-left: getRem(400);
        }

    }

    &-active {
        z-index: 1001;
        pointer-events: auto;
    }


}

.selected-item {
    @include breakpoint(tablet) {
        box-sizing: border-box;
        filter: drop-shadow(0 0 getRem(10) $secondary);
        border: 1px solid $secondary;
        position: absolute;

        span{
            position: fixed;
            right: getRem(2.5) !important;
        }
    }
}

.navs-img-container {
    display: flex;
    justify-content: center;
    width: getRem(40);

    @include breakpoint(tablet) {
        width: auto;
    }

    img {
        width: getRem(21);
        height: getRem(21);
    }
    .notification{
        position: absolute;
        margin-top: getRem(-3);
        left: getRem(45);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: getRem(12);
        height: getRem(12);
        background-color: $secondary;
        color: $onSecondary;
        border-radius: 100%;

        @include breakpoint(mobile_landscape){
            left: getRem(58);
        }

        @include breakpoint(tablet){
            right: getRem(18);
            left: auto;
        }
    }
}

.finish-onboarding {
    &-div {
        height: getRem(22);
        margin-top: getRem(2);
        margin-right: getRem(10);
        width: max-content;
        display: flex;
        padding: getRem(8) getRem(8);
        align-items: center;
        gap: getRem(4);
        background: $secondary;
        color: $onSecondary;
        border-radius: getRem(4);
        cursor: pointer;

        @include breakpoint(mobile_landscape){
            height: getRem(26);
            padding: getRem(8) getRem(16);
            gap: getRem(12);
            margin-top: 0;
            margin-right: getRem(24);
        }
    }

    &-info-icon {
        width: getRem(16) !important;
        height: getRem(16) !important;
    }
}

#navs-time-tracker-icon img {
    width: getRem(25);
    height: getRem(25);
}

#navs-employees-icon img {
    width: getRem(28);
    height: getRem(28);
}

#navs-settings-icon img {
    width: getRem(25);
    height: getRem(25);
}

.main-title {
    width: getRem(400) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    white-space: nowrap;
    color: $contentLeading;

    @include breakpoint(mobile_landscape) {
        width: auto !important;
        max-width: getRem(470) !important;
    }

    &-back-button{
        margin-top: getRem(-4);
        margin-right: getRem(10);
        cursor: pointer;
        color: $primary;
    }

    &-admin {
        @extend .main-title;
        
        padding-left: getRem(5);

        @include breakpoint(mobile_landscape) {
            padding-left: getRem(15);
        }

        @include breakpoint(tablet) {
            padding-left: getRem(0);
            margin-left: getRem(-10);
        }

        @include breakpoint(tablet_landscape) {
            padding-left: getRem(10);
        }

        @include breakpoint(desktop) {
            padding-left: getRem(50);
        }
    }
}

.newcoins-balance-info {
    display: flex;
    align-items: center;
    padding-top: getRem(2);
    cursor: pointer;
    margin-right: getRem(25);

    svg {
        margin-top: getRem(-3);
        width: getRem(20) !important;
        height: getRem(20) !important;
        overflow: visible;
    }

    span {
        color: $secondary;
        margin-right: getRem(6);
    }
}

.notifications-bell-span {
    position: relative;
    display: inline-block;
    margin-right: getRem(16);

    @include breakpoint(tablet_landscape) {
        margin-right: 0;
    }

    img {
        width: getRem(24);
        padding-bottom: getRem(4);

        @include breakpoint(mobile_landscape) {
            width: getRem(28);
        }
    }

    &-grey {
        filter: grayscale(100%);
    }

    &-circle {
        position: absolute;
        top: getRem(-1);
        left: getRem(14.5);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: getRem(17) !important;
        height: getRem(17) !important;
        padding-top: getRem(2);
        background-color: $secondary;
        color: $onSecondary;
        border-radius: 100%;

        @include breakpoint(mobile_landscape) {
            width: getRem(22) !important;
            height: getRem(22) !important;
            top: getRem(-5);
        }
    }
}



.ns-logo {
    width: getRem(40);
    @include breakpoint(tablet){
        border-radius: 50%;
        box-shadow: 0 0 getRem(10) $logoShadow;
    }
}

@media screen and (max-width: 768px) {
    .navs {
        transition: width 1s;
        -webkit-animation: slide 0.5s forwards;
        animation: slide 0.25s forwards;
    }
    @-webkit-keyframes slide {
        100% {
            left: 0;
        }
    }
    @keyframes slide {
        100% {
            left: 0;
        }
    }
    .selected-item-mobile {
        width: 100vw;
        background-color: rgba(255, 255, 255, 0.38);
        margin-left: -5vw;
        padding-left: 5vw;
        margin-right: -5vw;
    }
}

.toolbar-exit-button {
    width: max-content;
    margin-left: auto;
    padding-right: getRem(20);
    color: $contentSupporting;
    .logout-icon {
        width: getRem(25);
        height: getRem(25);
        color: $secondary;
    }
}

.private-mode-div {
    cursor: pointer;
    position: relative;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    div {
        width: 100%;
        text-align: center;
    }
}