@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.coupons {
    display: block;
    padding-bottom: getRem(48);

    &-header {
        @extend .table-base-header-type-1;

        &-button {
            @extend .table-base-header-type-1-button;
        }
    }

    &-filter {
        margin-left: getRem(16);
        margin-top: getRem(-8);
        margin-bottom: getRem(8);

        @include breakpoint(mobile_landscape) {
            margin-left: getRem(24);
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;
            min-width: getRem(700);

            &-first-column {
                padding-left: getRem(70);
            }

            &-amount {
                height: inherit;
                display: flex;
                flex-direction: row;
                gap: 8px;
                align-items: center;
                flex-wrap: nowrap;
                justify-content: flex-start;
                margin: auto 0;
            }

            &-row {
                td:last-child {
                    width: getRem(150) !important;
                }
            }

            th:first-child {
                padding-left: getRem(56);
            }
        }
    }

    &-amount-container {
        position: relative;
    }

    &-create-amount {
        background: none;
        color: $contentAccessory;
        position: absolute;
        top: getRem(34);
        left: getRem(13);
    }
}

.create-update-coupons-popup-label {
    color: $contentSupporting;
    margin-left: getRem(10);
    margin-top: 0;
}
