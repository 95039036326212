@import '../../styles/index.scss';

.coupon-claim {
  display: flex;
  justify-content: center;  
  align-items: center;
  height: 100vh;
  width: 100%;

  &-success {
    display: block;
    height: 100vh;
    width: 100vw;
    background-color: $primary;
    color: $onSecondary;
    overflow-y: auto;
    box-shadow: $shadowInfoDialog;
  }

  &-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90vw;

    img {
      margin-top: getRem(-70);
      width: getRem(150);
      height: getRem(150);
    }

    p {
      margin-top: getRem(70);
      text-align: center;
    }

    &-return-button {
      margin-top: getRem(50);
    }
  }
}
