@import '../../styles/index.scss';


.static-box-list {
  display: block;
  height: getRem(228);
  border: getRem(0.5) solid rgba(1, 33, 56, 0.5);
  border-radius: getRem(8);
  overflow: hidden;

  &-label {
    padding-left: getRem(10);
    color: $contentSupporting;
  }

  &-progress {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-table {
    display: block;
    height: 100%;
    padding-top: getRem(10);
    overflow-y: auto;

    &::-webkit-scrollbar {
      @extend .custom-scroll-webkit-scrollbar;
    }
    &::-webkit-scrollbar-track {
      @extend .custom-scroll-webkit-scrollbar-track;
    }
    &::-webkit-scrollbar-thumb {
      @extend .custom-scroll-webkit-scrollbar-thumb;
    }

    &-row {
      position: relative;
      display: block;
      padding-left: getRem(15);
      width: 100%;
      overflow-x: clip;
      text-overflow: ellipsis;

      &:hover {
        background-color: $backgroundControls;
        color: $contentLeading;
      }

      span {
        color: $contentSupporting;
      }

      &-additional {
        display: block;
        margin-top: getRem(2);
        color: $contentAccessory;
        padding-left: getRem(16);
      }
    }
  }
}