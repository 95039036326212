@import '../../styles/index.scss';


.toolkit-multiple-dd-list {
    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: getRem(8);

        .toolkit-dd-list-select {
            &__control {
                width: calc(100% - getRem(40.5)) !important;
                border-radius: 0 !important;
                border-bottom-left-radius: getRem(8) !important;
                border-top-left-radius: getRem(8) !important;
                border-right: none !important;
            }

            &__indicator-separator {
                background-color: $surface !important;
            }

            &__option {
                &--is-disabled {
                    color: $contentAccessory !important;
                }

                &:first-child {
                    margin-top: getRem(0) !important;
                }
            }
        }

        &-img {
            color: $secondary;
            width: getRem(14) !important;
            height: getRem(14) !important;
        }

        &-info {
            &-img {
                position: absolute;
                right: getRem(58);
                top: getRem(8);

                .icon-info {
                    color: $contentAccessory;
                    width: getRem(14);
                    height: getRem(14);
                }    
            }
            
            &-wrapper {
                display: none;
            }

            &-card {
                position: absolute;
                display: block;
                width: getRem(137);
                min-height: getRem(44);
                bottom: getRem(37);
                right: getRem(-5);
                border-radius: getRem(7);
                background-color: $surface;
                box-shadow: $shadowInfoCard;
                padding: getRem(8);

                &-title {
                    display: inline-block;
                    width: 100%;
                    color: $contentSupporting;
                }

                &-text {
                    display: inline-block;
                    width: 100%;
                    color: $contentSupporting;
                }

                &-triangle {
                    display: block;
                    position: absolute;
                    right: getRem(60);
                    bottom: getRem(26);
                    width: 0;
                    height: 0;
                    border-left: getRem(5) solid transparent;
                    border-right: getRem(5) solid transparent;
                    border-top: getRem(10) solid $surface;
                }
            }
        }
    }
    
    &-button {
        display: block;
        position: relative;
        width: 100%;
        
        &-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            width: getRem(40.5);
            height: getRem(40);
            border: getRem(0.5) solid $disabled !important;
            border-left: none !important;
            border-bottom-right-radius: getRem(8);
            border-top-right-radius: getRem(8);
        }

        &-inner {
            display: block;
            width: 100%;
            height: calc(100% - getRem(10));
            margin-top: getRem(5);
            margin-bottom: getRem(5);
            border-left: $border getRem(0.5) solid;
        }
    }

    &-new-item {
        display: block;
        width: 100%;
        text-align: right;

        span {
            color: $contentSupporting;
        }
    }

    &-show {
        display: block;
    }

    &-hide {
        &-block {
            display: none;
        }
    }
}
