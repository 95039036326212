@import "../../styles/index.scss";

.close-popup-icon {
    width: getRem(14);
    height: getRem(14);
    position: absolute;
    top: getRem(21);
    right: getRem(21);
    cursor: pointer;
    color: $disabled !important;

    &:hover {
        color: $secondaryVariant !important;
    }
}
