@import "../../styles/index.scss";


$box-shadow-mobile: getRem(0) getRem(0) getRem(100) rgba(255, 255, 255, 0.5);
$box-shadow-desktop: getRem(0) getRem(-40) getRem(250) rgba(255, 255, 255, 0.7);

.greetings {

    &-wrapper {
        display: block;
        height: 100vh;
        width: 100vw;
        background-color: $primary;
        color: $onSecondary;
        overflow-y: auto;
        box-shadow: $shadowInfoDialog;

        @include breakpoint(mobile_landscape) {
            width: getRem(1092);
            max-width: 100vw;
            border-radius: getRem(8);
        }

        @include breakpoint(tablet_landscape) {
            max-width: 80vw;
        }

        @include breakpoint(desktop) {
            max-height: 95vh;
            height: max-content;
            overflow: hidden;
        }
    }

    &-anniversary {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: getRem(100);

        @include breakpoint(mobile_landscape) {
            padding-bottom: getRem(56);
        }

        &-logo-ns {
            position: absolute;
            width: getRem(120);
            height: getRem(120);
            right: getRem(-160);
            top: getRem(-100);
            box-shadow: $box-shadow-mobile;
            border-radius: 50%;
            
            @include breakpoint(mobile_landscape) {
                width: getRem(171);
                height: getRem(171);
                right: getRem(-250);
                top: getRem(-67);
                z-index: 1001;
                box-shadow: $box-shadow-desktop;
            }
        }

        &-content {
            display: block;
            height: max-content;
            padding-right: getRem(150);
            padding-top: getRem(185);

            @include breakpoint(mobile_landscape) {
                padding-top: getRem(153);
            }
        }

        &-year {
            position: relative;
            font-size: getRem(200);
            font-weight: 600;
            line-height: getRem(220);

            &-info {
                display: flex;
                flex-direction: column;
                position: absolute;
                right: getRem(-165);
                top: getRem(8);

                > span:first-child {
                    margin-bottom: getRem(16);
                }

                span {
                    z-index: 1002;
                }
            }
        }

        &-thanks {
            text-align: center;
            display: flex;
            flex-direction: column;
            margin-bottom: getRem(50);

            &-message {
                margin-top: getRem(10);
                max-width: 90vw;

                @include breakpoint(tablet_landscape) {
                    max-width: 65vw;
                }

                @include breakpoint(desktop) {
                    max-width: 100vw;
                }
            }
        }

        button {
            position: relative;
            z-index: 1001;

            @include breakpoint(mobile_landscape) {
                bottom: auto;
            }
        }
    }

    &-welcome {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: getRem(760) !important;
        height: getRem(560) !important;
        border-radius: getRem(16);
        background-color: $surface;

        &-logo-ns {
            margin-top: getRem(40);
            width: getRem(144);
            height: getRem(144);
            box-shadow: $box-shadow-mobile;
            border-radius: 50%;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: getRem(90);

            &-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                span {
                    color: $secondary;
                }
            }

            &-text {
                color: $contentSupporting !important;
                margin-top: getRem(9);
            }
        }

        &-button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: getRem(10);
            border-radius: getRem(10);
            border: none;
            background-color: $secondary;
            color: $onSecondary;
            height: getRem(33);
            padding: getRem(8) getRem(32);
            margin-top: getRem(160);

            &:hover {
                background-color: $secondaryVariant;
                cursor: pointer;
                border: none;
            }

            &:focus {
                outline: none;
            }

            &-close {
                position: absolute;
                right: getRem(32);
                top: getRem(32);
                color: $disabled;
                height: 32px;
                width: 32px;

                &:hover {
                    color: $secondary;
                    cursor: pointer;
                }
            }
        }

        &-social-medias {
            display: inline-flex;
            align-items: flex-start;
            gap: getRem(24);
            margin-top: getRem(16);
            margin-bottom: getRem(-40);

            .media-icon {
                width: getRem(40);
                height: getRem(40);
                color: $contentSupporting !important;

                &:hover {
                    color: $secondary !important;
                }
            }
        }
    }

    &-onboarding {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: getRem(840) !important;
        height: getRem(552);
        border-radius: getRem(16);
        background-color: $surface;
        padding: 0 getRem(16);
        gap: getRem(40);

        &-heading {
            color: $secondary;
        }

        &-content {
            display: flex;
            top: getRem(128);

            &-heading {
                color: $contentLeading;
                margin-bottom: getRem(16);
            }

            &-paragraph {
                margin-bottom: getRem(40);
            }

            &-text {
                color: $contentSupporting;

                p {
                    margin-bottom: getRem(24) !important;
                }
            }

            a {
                color: $secondary;
                text-decoration: underline;

                &:hover {
                    color: $secondaryVariant;
                }
            }

            li {
                margin: 0;
            }
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: getRem(815);
            position: absolute;
            bottom: getRem(40);
            left: getRem(56);

            &-indicator {
                display: flex;
                gap: getRem(8);

                &-step {
                    width: getRem(20);
                    height: getRem(3);
                    border-radius: getRem(30);
                    background-color: $disabled
                }

                &-step-active {
                    @extend .greetings-onboarding-footer-indicator-step;
                    background-color: $secondary;
                }
            }

            &-buttons {
                display: flex;
                align-items: center;
                gap: getRem(24);
            }

            &-button-next {
                display: flex;
                height: getRem(33);
                padding: getRem(8) getRem(32);
                justify-content: center;
                align-items: center;
                gap: getRem(10);
                border-radius: getRem(8);
                background-color: $secondary;
                color: $onSecondary;
                border: none;

                &:hover {
                    background-color: $secondaryVariant;
                    cursor: pointer;
                    border: none;
                }

                &:focus {
                    outline: none;
                }
            }

            &-button-prev {
                display: flex;
                width: getRem(36);
                height: getRem(17);
                justify-content: center;
                align-items: center;
                background: none;
                color: $disabled;
                border: none;

                &:hover {
                    color: $primary;
                    cursor: pointer;
                    border: none;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .our-team {
            &-popup {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                width: 100%;
                height: 100%;
                gap: getRem(12);

                &-featured-media {
                    grid-column-start: 1;
                    grid-column-end: 4;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    border-radius: getRem(16);
                    position: relative;

                    iframe {
                        border-radius: getRem(16);
                        width: 100%;
                        height: 100%;
                    }
                }

                &-media-thumbnail {
                    width: max-content;
                    height: max-content;

                    img {
                        width: getRem(125);
                        height: getRem(110);
                        border-radius: getRem(16);
                    }
                }
            }
        }

        .benefit {
            &-item {
                width: getRem(164);
                height: getRem(164);
                display: flex;
                color: $contentSupporting;
                flex-direction: column;
                align-items: center;

                img {
                    width: getRem(73.591);
                    height: getRem(73.591);
                    margin-bottom: getRem(23.59);
                }
            }

            &-popup {
                &-grid {
                    margin-top: getRem(15);
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 7%;
                    justify-items: center;
                    justify-content: space-between;
                    align-content: center;
                    align-items: end;
                }

            }
        }

        .useful-contacts {
            &-popup {
                &-grid {
                    margin-top: getRem(-10);
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 0;
                    width: getRem(728);
                    align-items: flex-start;
                }
            }

            &-person {
                display: flex;
                align-items: center;
                gap: getRem(16);
                margin: getRem(15);
                align-self: stretch;

                &-photo {
                    width: getRem(60);
                    height: getRem(60);
                    border-radius: getRem(8)
                }

                &-name {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: getRem(4);
                    flex: 1 0 0;
                }
            }

            &-position {
                color: $contentSupporting;
            }

            &-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &-info-list {
                color: $contentSupporting;

                li {
                    margin-bottom: getRem(8.5);
                }
            }

        }

    }

    &-birthday {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: getRem(64);
        padding-bottom: getRem(100);

        @include breakpoint(mobile_landscape) {
            padding-bottom: getRem(80);
        }

        @include breakpoint(desktop) {
            padding-bottom: getRem(54);
        }

        &-logo-ns {
            width: getRem(96);
            height: getRem(96);
            margin-bottom: getRem(40);
        }

        &-title {
            font-size: getRem(74);
            font-weight: 600;
            line-height: getRem(91);
            margin-bottom: getRem(106);

            @include breakpoint(mobile_landscape) {
                margin-bottom: getRem(85);    
            }
        }

        &-content {
            margin-bottom: getRem(170);
            padding-left: getRem(40);
            width: 100%;
            position: relative;

            &-newcoins {
                position: absolute;
                bottom: getRem(155);
                z-index: 1001;

                @include breakpoint(tablet) {
                    position: absolute;
                    bottom: getRem(115);
                    z-index: 1001;
                }
            }

            @include breakpoint(mobile_landscape) {
                margin-bottom: getRem(100);
                padding-left: 0;
                width: auto;
            }

            @include breakpoint(desktop) {
                margin-bottom: getRem(70);
                padding-left: 0;
                width: auto;
            }
        }

        &-year {
            width: getRem(32);
            height: getRem(32);
            padding-top: getRem(0);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: getRem(65);
            top: getRem(327);
            background-color: $surface;
            color: $primary;
            opacity: 0.8;
            border-radius: 50%;
            z-index: 1001;

            @include breakpoint(desktop) {
                width: getRem(64);
                height: getRem(64);
                top: getRem(209);
                right: getRem(115);
            }
        }

        &-cake {
            width: getRem(189);
            height: getRem(234);
            position: absolute;
            right: 0;
            top: getRem(229);
            z-index: 1000;

            @include breakpoint(desktop) {
                width: getRem(349);
                height: getRem(450);
                top: getRem(30);
            }
        }

        &-confetti {
            height: getRem(577);
            height: 100%;
            width: getRem(738);
            position: absolute;
            right: getRem(-20);
            top: getRem(0);
            z-index: 1000;
            transform: scale(0.65);
            
            right: getRem(-77);
            top: getRem(-131);

            @include breakpoint(mobile_landscape) {
                transform: scale(0.8);
                right: getRem(20);
                top: getRem(-40);
            }

            @include breakpoint(desktop) {
                top: 0;
                left: 0;
                transform: none;
            }
        }

        button {
            position: fixed;
            bottom: getRem(100);
            z-index: 1001;

            @include breakpoint(mobile_landscape) {
                position: relative;
                bottom: auto;
            }
        }
    }

    &-newcoins {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-top: getRem(64);
        padding-bottom: getRem(100);
        height: 100%;
        justify-content: center;

        @include breakpoint(mobile_landscape) {
            padding-bottom: getRem(80);
        }

        @include breakpoint(desktop) {
            width: auto;
            padding-bottom: getRem(124);
        }

        &-logo-ns {
            z-index: 1001;
            width: getRem(96);
            height: getRem(96);
            position: absolute;
            top: getRem(50);

            @include breakpoint(mobile_landscape) {
                position: relative;
                margin-bottom: getRem(40);
            }
        }

        &-title {
            font-size: getRem(90);
            font-weight: getRem(600);
            margin-top: getRem(31);
            text-transform: uppercase;
            line-height: getRem(180);
            display: flex;
            align-items: center;
            gap: getRem(16);
            z-index: 1001;

            @include breakpoint(mobile_landscape) {
                font-size: getRem(90);
            }

            @include breakpoint(desktop) {
                margin-bottom: getRem(25);
                gap: getRem(32);
                font-size: getRem(150);
            }

            svg {
                width: getRem(60);
                height: getRem(60);
                stroke-width: 0.5 !important;

                @include breakpoint(desktop) {
                    width: getRem(90) !important;
                    height: getRem(90) !important;
                }
            }
        }

        &-content {
            width: 100%;
            position: relative;
            margin-bottom: getRem(20);
            display: flex;
            justify-content: center;
            text-align: center;
            z-index: 1001;

            @include breakpoint(mobile_landscape) {
                margin-bottom: getRem(8);
                padding-left: 0;
                width: auto;
            }

            @include breakpoint(desktop) {
                padding-left: 0;
                width: auto;
            }
        }

        &-confetti {
            height: getRem(577);
            height: 100%;
            width: getRem(738);
            position: absolute;
            right: getRem(-20);
            top: getRem(0);
            z-index: 1000;
            transform: scale(0.65);

            right: getRem(-77);
            top: getRem(-131);

            @include breakpoint(mobile_landscape) {
                transform: scale(0.8);
                right: getRem(20);
                top: getRem(-40);
            }

            @include breakpoint(desktop) {
                top: 0;
                left: 0;
                transform: none;
            }
        }

        button {
            flex-shrink: 0;
            position: fixed;
            bottom: getRem(100);
            z-index: 1001;

            @include breakpoint(mobile_landscape) {
                position: relative;
                bottom: auto;
            }

            @include breakpoint(desktop) {
                margin-top: getRem(25);
            }
        }
    }
}

.shortened {
    height: getRem(460) !important;
}