@import '../../styles/index.scss';

.info-message {
    margin-right: getRem(6);
    cursor: pointer;
    flex-shrink: 0;

    &-icon {
        margin-top: getRem(-2);
        width: getRem(14) !important;
        height: getRem(14) !important;
        color: $secondary;
        opacity: 0.5;
    }

    &-content {
        position: absolute;
        background-color: $background;
        width: getRem(282);
        height: max-content;
        overflow: auto;
        z-index: 1;
        box-shadow: $shadowInfoCard;
        border-radius: 10px;
        justify-items: left;
        padding: getRem(16);
        margin-top: getRem(0);
        margin-left: getRem(-270);
        white-space: break-spaces;

        p {
            margin-bottom: 0;
            @extend .t-b1;
            color: $contentSupporting;
        }
    }
}