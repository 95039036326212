@import '../../styles/index.scss';


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.search {
    &-input {
        border: 0;
        border-radius: getRem(10);
        background: $backgroundControls;
        width: 100%;
        padding: getRem(6) getRem(20) getRem(6) getRem(20);
        outline: none;
    
        &:focus {
            box-shadow: $shadowSearch;
        }

        &-form {
            position: relative;
            width: 100%;
            display: inline-block;
            align-items: center;
            @include breakpoint(mobile_landscape) {
                width: getRem(464);
            }
        }
    }
    
    &-icon {
        height: getRem(14);
        width: getRem(14);
        color: $contentAccessory;
        position: absolute;
        top: 20%;
        right: getRem(14);
        cursor: pointer;
    }

    &-user-wrap {
        background: $background;
        padding: getRem(4) getRem(8);
        border-radius: getRem(7);
        position: absolute;    
        width: max-content;
        top: 15%;
        left: getRem(15);
    }
}
