@import '../../styles/index.scss';

.error {
    background: linear-gradient(119.51deg, #1E1E1E 42.72%, rgba(0, 0, 0, 0) 293.93%);
    text-align: center;
    height: 100vh;

    img {
        margin-top: 15vh;
        width: getRem(120);
        user-select: none;
    }

    &-info {
        margin-top: getRem(70);
        color: $onSecondary;
    }

    &-return-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: getRem(120);
    }
}
