
.scene {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: clip;

  &--opening {
    @extend .scene;
    overflow: visible;
  }
}

.deer {
  width: 0;
  height: 0;
  animation-name: up, across;
  animation-timing-function: ease-in-out, linear;
  animation-iteration-count: 1, 1;
  position: absolute;
  left: -80px;
  z-index: 9999;
  filter: blur(1px);

  &--first {
    @extend .deer;
    animation-delay: 1s, 1s;
    animation-duration: 4s, 4s;
  }

  &--second {
    @extend .deer;
    animation-delay: 1.06s, 1.06s;
    animation-duration: 3.98s, 3.98s;

  }

  &--third {
    @extend .deer;
    animation-delay: 1.1s, 1.1s;
    animation-duration: 4s, 4s;
  }

  &--forth {
    @extend .deer;
    animation-delay: 1.14s, 1.14s;
    animation-duration: 4s, 4s;
  }

  &--sleigh {
    @extend .deer;
    animation-delay: 1.24s, 1.24s;
    animation-duration: 3.94s, 3.94s;
  }
}


@keyframes up {
  0% {
    transform: translateY(0%);
  }

  20% {
    transform: translateY(80px);
  }

  50% {
    transform: translateY(-60px);
  }

  80% {
    transform: translateY(70px);
  }

  100% {
    transform: translateY(-50px);
  }
}

@keyframes across {
  from {
    left: 0%;
  }

  to {
    left: 100%;
  }
}