@import '../../../styles/index.scss';


.audit-logs-side-panel {
  color: $blackTransparent;
  font-family: "Gilroy", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.1rem;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
}