@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.employee-positions {
    display: block;
    padding-bottom: getRem(48);

    &-header {
        @extend .table-base-header-type-1;

        &-button {
            @extend .table-base-header-type-1-button;
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;
            min-width: getRem(700);

            &-first-column {
                padding-left: getRem(56);
                color: $contentSupporting;
                width: getRem(500) !important;
            }

            &-row {
                td:last-child {
                    width: getRem(150) !important;
                }
            }

            th:first-child {
                padding-left: getRem(56);
            }
        }
    }
}

.create-update-employee-position-popup-label {
    color: $contentSupporting;
    margin-left: getRem(10);
    margin-top: 0;
}
