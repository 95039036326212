@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.ns-table-container {
  display: flex;
  height: 90vh;
  overflow: scroll;
}

.ns-table-wrapper {

}

.ns-table-sidepanel {
  width: 35%;
  vertical-align: top;
  position: sticky;
  align-content: start;
  background-color: $background;
  box-shadow: 0 0 0.4rem #1E1E1E1A;
  margin-right: 1.25rem;
  top: 0.25rem;
  height: fit-content;
  line-break: anywhere;
  overflow: scroll;

  &-header {
    padding-left: 8px;
    padding-top: 8px;
  }

  &-content {
    padding-left: 16px;
  }
}

.ns-table {
  @extend .table-base;

  overflow-y: auto;

  &-small {
    border-spacing: 0 0.5rem;
  }

  min-width: getRem(700);
  max-width: 100vw;

  table-layout: fixed;

  &-first-column {
    padding-left: getRem(36);
  }

  &-row {
    &-small {
      height: 2.5rem !important; // It's here until we get rid of custom-scrollable-table
    }

    &-selectable {
      &:hover {
        cursor: pointer;
      }
    }

    &-selected {
      box-shadow: 0 0 0.2rem $secondary !important;
      background: $backgroundSelected !important;
    }

    td:last-child {
      width: getRem(150) !important;
    }
  }

  th:first-child {
    padding-left: getRem(36);
  }

  td {
    font-family: "Gilroy", serif !important;
    color: $blackTransparent !important;
    padding-right: getRem(12)
  }

  th {
    padding-right: getRem(12)
  }

}


