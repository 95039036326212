@import "../../styles/index.scss";

.small-button {
  color: $onSecondary;
  min-width: getRem(113);
  height: getRem(33);
  background: $secondary;
  border: getRem(1) solid $secondary;
  border-radius: getRem(8);
  cursor: pointer;
  padding-left: getRem(32);
  padding-right: getRem(32);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $secondaryVariant;
    color: $onSecondary;
  }

  &:disabled {
    border-color: $disabled;
    background-color: $disabled;
    cursor: default;
  }

  &-default-loading {
    @extend .small-button;

    &:disabled {
      border-color: $secondary;
      background-color: $secondary;
    }
  }

  &-secondary {
    @extend .small-button;
    background: $onSecondary;
    color: $secondary;

    &:hover {
      color: $secondaryVariant;
      border-color: $secondaryVariant;
      background-color: $onSecondary;
    }

    &:disabled {
      border-color: $disabled;
      color: $disabled;
      background-color: $onSecondary;
      cursor: default;
    }
  }
}
