@import '../../styles/index.scss';


.settings {
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-top: getRem(24);
    padding-bottom: getRem(60);
    padding-left: getRem(28);
    padding-right: getRem(28);

    @include breakpoint(tablet) {
        padding-top: getRem(48);
    }

    @include breakpoint(desktop) {
        flex-direction: row;
        padding-left: getRem(41);
        padding-right: getRem(0);
    }

    &-box {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        gap: getRem(24);
        justify-content: center;
        align-items: center;

        @include breakpoint(mobile_landscape) {
            justify-content: start;
            padding-left: getRem(12);
        }

        @include breakpoint(tablet) {
            width: getRem(662);
            padding-left: getRem(20);
            gap: getRem(40);
        }

        @include breakpoint(desktop) {
            width: getRem(962);
            padding-left: getRem(0);
            gap: getRem(32);
        }

        &-card {
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border: none !important;
            border-radius: getRem(8);
            height: getRem(180) !important;
            width: getRem(298) !important;

            @include breakpoint(mobile_landscape) {
                width: getRem(280);
            }

            @include breakpoint(desktop) {
                width: getRem(298) !important;
            }

            &-content {
                width: 100%;
                padding-left: getRem(24);
                padding-top: getRem(28);
                
                &-title {
                    color: $contentLeading;
                    margin-bottom: getRem(8);
                }
    
                &-value {
                    color: $contentHighlightPrimary;
                }

                &-sub-value {
                    margin-top: getRem(16);
                    color: $contentSupporting;
                }
            }

            &-button-container {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: getRem(24);
            }
        }
    }

    &-column {
        width: getRem(298);
        margin-top: getRem(24);
        margin-left: getRem(8);
        display: flex;
        flex-direction: column;
        gap: getRem(24);

        @include breakpoint(tablet) {
            width: getRem(278);
            margin-left: getRem(20);
            gap: getRem(32);
        }

        @include breakpoint(desktop) {
            width: getRem(298);
            margin-left: getRem(24);
            margin-top: 0;
        }

        &-feature-flag {
            display: flex;
            flex-direction: row;
            padding: getRem(24);
            align-items: center;
            justify-content: space-between;
            border-radius: getRem(8);
            background-color: $surface;
            box-shadow: $shadowInfoCard;
        }

        &-feature-flag-title {
            color: $contentLeading;
        }

        &-feature-flag-toggle {
            width: getRem(24);
            height: getRem(12);
            cursor: pointer;

            &-active {
                @extend .settings-column-feature-flag-toggle;
                color: $secondary;
            }

            &-inactive {
                @extend .settings-column-feature-flag-toggle;
                color: $contentAccessory;
            }
        }
    }
}
