@import '../../styles/index.scss';

.info-alert-message {
  background-color: white !important;
  color: $contentLeading !important;
  box-shadow: $shadowInfoCard;
}

.custom-alert-message {
  margin: getRem(10) getRem(14) !important;
  border-radius: getRem(8) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: getRem(8);

  .MuiAlert-icon {
    padding: 0 !important;
    margin: 0 !important;

    svg {
      width: getRem(20) !important;
      height: getRem(20) !important;
    }
  }
}
