@import '../../styles/index.scss';


.dynamic-box-list {
    display: block;
    height: getRem(299);
    border: getRem(0.5) solid rgba(1, 33, 56, 0.5);
    border-radius: getRem(8);
    overflow: hidden;

    &-ext {
        @extend .dynamic-box-list;
        height: getRem(232);
    }

    &-label {
        padding-left: getRem(10);
        color: $contentSupporting;
    }

    &-search {
        display: block;
        height: getRem(72);
        padding: getRem(16) getRem(15) getRem(16) getRem(15);

        form {
            width: 100%;
            height: getRem(40);
        }
    }

    &-progress {
        height: 100%;
        display: flex;
        align-items: center;        
    }

    &-table {
        display: block;
        height: 100%;
        padding: 0;
        overflow-y: auto;

        &::-webkit-scrollbar {
            @extend .custom-scroll-webkit-scrollbar;
        }              
        &::-webkit-scrollbar-track {
            @extend .custom-scroll-webkit-scrollbar-track;
        }
        &::-webkit-scrollbar-thumb {
            @extend .custom-scroll-webkit-scrollbar-thumb;
        }
    
        &-row {
            position: relative;
            display: block;
            width: 100%;
            padding: getRem(8.5) 0 getRem(6.5);
            overflow-x: clip;
            text-overflow: ellipsis;
            letter-spacing: normal;

            &:hover {
                background-color: $backgroundControls;
                color: $contentLeading;
            }

            span {
                overflow-x: clip;
                color: $contentSupporting;
                padding: 0 getRem(20) 0 getRem(16);
            }

            &-img-wrap {
                display: inline-block;
                padding-left: getRem(16);

                img {
                    width: getRem(24);
                    height: getRem(24);
                    border-radius: 100%;
                    margin-right: getRem(-8);
                }
            }

            .add-icon {
                position: absolute !important;
                width: getRem(14) !important;
                height: getRem(14) !important;
                right: getRem(15) !important;
                top: getRem(13) !important;
                cursor: pointer;
                color: $secondary;
                opacity: 0.5 !important;
            }

            .delete-icon {
                @extend .add-icon;
                color: $contentAccessory;
            }

            &-additional {
                display: block;
                margin-top: getRem(2);
                color: $contentAccessory;
                padding-left: getRem(16);
            }
        }
    }
}

.dynamic-box-list-search ~ .dynamic-box-list-table {
    height: calc(100% - getRem(72));
}
