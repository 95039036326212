@import '../../styles/index.scss';
@import '../../styles/common.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.time-tracker {
  width: getRem(375);
  overflow-x: hidden;
  @include breakpoint(mobile_landscape) {
    width: 100%;
  }

  &-reason-disabled {
    padding-top: getRem(10);
    color: $contentAccessory;
  }

  &-content { 
    @include breakpoint(mobile_landscape) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include breakpoint(tablet_landscape) {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: unset;
    }

    &-alt-label {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: getRem(8);
      margin-top: getRem(60);
    }

    &-loader {
      padding-top: getRem(30);
    }

    &-time-records {
      @include breakpoint(tablet_landscape) {
        padding-bottom: getRem(64);
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: getRem(64);
    
      @include breakpoint(tablet_landscape) {
        margin-top: getRem(32);
      }

      &-card {
        position: relative;
        background-color: $surface;
        box-shadow: $shadowInfoCard;
        border-radius: getRem(8);
        height: getRem(136);
        padding-top: getRem(24);
        padding-left: getRem(24);
        margin-bottom: getRem(24);
        width: getRem(343);

        @include breakpoint(tablet) {
          width: getRem(286);
        }

        &-time-off {
          @extend .t-s4;

          &-icon {
            width: getRem(22) !important;
            height: getRem(24) !important;
            color: $transparentOrange;
            position: absolute;
            right: getRem(16);
            top: getRem(16);
          }

          &-wrapper {
            position: absolute;
            bottom: getRem(13);
          }

          &-period {
            color: $contentAccessory;
            padding-right: getRem(4);
          }

          &-approved {
            @extend .time-tracker-content-info-card-time-off;
            color: $contentSuccess;
          }

          &-in-review {
            @extend .time-tracker-content-info-card-time-off;
            color: $contentHighlightPrimary;
          }

          &-declined {
            @extend .time-tracker-content-info-card-time-off;
            color: $contentError;
          }
        }

        h1 {
          margin-top: getRem(8);
          color: $contentHighlightPrimary;
        }
      }
    }

    &-records {
      width: 100vw;
      display: inline-block;
      margin-bottom: getRem(-24);
      margin-top: getRem(40);
      overflow-x: auto;
      white-space: nowrap;

      @include breakpoint(tablet) {
        width: calc(100vw - getRem(70));
      }

      @include breakpoint(tablet_landscape) {
        width: getRem(400);
        margin-top: getRem(48);
        margin-bottom: 0;
      }

      @include breakpoint(desktop) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &-table {
        min-width: getRem(850);
        border-spacing: 0 getRem(16);
        border-collapse: separate;      
        padding: getRem(0) getRem(16) getRem(0);

        @include breakpoint(desktop) {
          width: 100%;
          padding-left: getRem(6);
          padding-right: getRem(6);
        }

        &-link {
          @extend .custom-scrollable-table-link;
          margin-right: getRem(15);
        }

        th {
          margin-top: getRem(15);
          color: $contentLeading;

          &:first-child {
            padding-left: getRem(40) !important;
          }
        }

        td:nth-child(4) {
          min-width: getRem(90) !important;
        }

        td:nth-child(5) {
          min-width: getRem(150) !important;
        }

        td:last-child {
          width: getRem(110);
        }

        &-arrow {
          position: relative;
          height: getRem(16);
          display: flex;
          width: getRem(349);
          justify-content: center;
          align-items: center;
        }

        &-item {
          height: getRem(56);
          border-radius: getRem(8);
          background: $background;

          &:hover {
              background: $backgroundControls;
          }

          &:hover .time-tracker-content-records-table-item-pin-icon {
            visibility: visible;
          }

          @include breakpoint(mobile_landscape) {
            box-shadow: $shadowTableRow;
          }

          td {
            @extend .table-box-shadow-styles-td;
            position: relative;
          }
      
          td:first-child {
            padding-left: getRem(40);
            @extend .table-box-shadow-styles-td-first-child;
          }
      
          td:last-child {
            @extend .table-box-shadow-styles-td-last-child;
          }

          &-label {
            background-color: $secondary;
            position: absolute;
            margin-bottom: 0;
            border-radius: getRem(3);
            width: max-content;
            color: white;
            padding: getRem(4) getRem(10);
            margin-left: getRem(-10);
            margin-top: getRem(-39);
            display: block;
    
            @include breakpoint(mobile_landscape) {
              padding: getRem(2) getRem(8);
            }
          }

          &-pin-icon {
            position: absolute;
            top: getRem(21.75);
            left: getRem(16);
            width: getRem(15) !important;
            height: getRem(15) !important;
            color: $contentAccessory;
            transform: rotate(315deg);
            visibility: hidden;

            &:hover {
              color: $secondary;
              cursor: pointer;
            }

          }
        }
      }
    }

    &-calendar {
      width: 100%;
      @include breakpoint(mobile_landscape) {
        width: getRem(433);
      }

      @include breakpoint(tablet) {
        height: getRem(440);
      }

      @include breakpoint(desktop) {
        margin-top: getRem(32);
        height: auto;
      }

      &-picker {
        display: flex;
        justify-content: center;
        @include breakpoint(mobile_landscape) {
          width: getRem(433);
        }
      }

      &-buttons {
        display: flex;
        gap: getRem(20);
        justify-content: center;
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: getRem(26);
      position: relative;

      @include breakpoint(tablet_landscape) {
        margin-top: getRem(51);
      }

      @include breakpoint(desktop) {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: getRem(0);
        width: getRem(934);
      }

      &-hours-chips {
        position: relative;
        width: 100%;
        display: inline-flex;
        border: getRem(0.5) solid $contentAccessory;
        border-radius: getRem(8);
        overflow: hidden;
        padding: getRem(5) 0;
        margin-bottom: getRem(13);
        height: getRem(40);
        background-color: $background;

        &-selected{
          background: $secondary !important;
          color: $onSecondary !important;
        }

        .without-time-off {
          width: 50%;
        }

        button {
          color: $contentSupporting;
          align-items: center;
          text-align: center;
          position: relative;
          width: 33.3%;
          border: none;
          cursor: pointer;
          margin: 0 getRem(5);
          border-radius: getRem(8);
          background: $background;


          &:hover {
            background: $backgroundControls;
          }

          &:not(:first-child):before {
            content: '';
            display: block;
            position: absolute;
            left: getRem(-4.5);
            top: getRem(3);
            transform: translateX(-50%);
            height: 80%;
            width: getRem(0.5);
            background: $contentAccessory
          }
        }

        button:last-child {
          border-right: none;
        }
      }

      &-inputs {
        width: getRem(343);
        display: flex;
        flex-direction: column;

        @include breakpoint(mobile_landscape) {
          margin-top: getRem(32);
          width: getRem(430);
        }

        &-label {
          margin-left: getRem(8);
        }

        .container {
          margin: getRem(20) 0;
        }

        &-loading {
          position: absolute;
          bottom: getRem(20);
          left: getRem(190);
        }

        &-time-off-label {
          color: $contentSupporting;
          height: max-content;

          @include breakpoint(mobile) {
            max-width: getRem(343);
            position: absolute;
            bottom: getRem(-40);
          }

          @include breakpoint(desktop) {
            top: getRem(280);
            max-width: getRem(430);
            position: absolute;
          }

        }

        &-button {
            display: flex;
            justify-content: center;
            margin-top: getRem(16);

            &-bottom {
              margin-top: getRem(16);

                @include breakpoint(desktop) {
                    margin-top: getRem(65);
                }

              button {
                width: 100%;
              }
            }

            button {
              width: 100%;
            }
        }

        &-reason {
            padding-top: getRem(23);
            color: $contentAccessory;
            text-align: center;
        }

        &-template {
          display: flex;
          width: 102% !important;
          align-items: flex-start;
          align-content: flex-start;
          gap: getRem(8);
          margin-top: getRem(14);
          flex-wrap: wrap;

          &-bubble{
            padding: getRem(10);
            align-items: center;
            border-radius: getRem(8);
            border: getRem(0.5) solid $border;
            position: relative;
            display: inline-flex;
            color: $contentSupporting;
            background-color: $background;

            &-project{
              max-width: getRem(131);
              margin-right: 0;
              padding-right: 0;
              text-align: left;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &:hover {
              border: getRem(0.5) solid $calendarTodayAlt;
              cursor: pointer;
              color: $calendarTodayAlt;
            }
          }

          &-remove {
            visibility: hidden;
            position: absolute;
            right: getRem(-7);
            top: getRem(-7);
            width: getRem(16) !important;
            height: getRem(16) !important;
            color: $contentAccessory;
            z-index: 2;
            background-color: $background;

            &:hover {
              color: $calendarTodayAlt;
            }
          }
        }

        &-duration {
          position: relative;

          .slider-alternative-position {
            top: getRem(27.5) !important;
          }

          .label-alternative-position {
            top: getRem(37.5) !important;
          }

          .slider{
            position: absolute;
            height: getRem(30) !important;
            width: getRem(48) !important;
            margin-bottom: getRem(10);
            margin-top: getRem(2);
            right: getRem(-3);
            top: getRem(23.5);
            color: $secondary;
            cursor: pointer;

            &-label {
              color: $contentSupporting;
              position: absolute;
              right: getRem(42);
              top: getRem(34);
              cursor: pointer;
            }

            &-inactive {
              @extend .slider;
              color: $disabled;
            }
          }
        }

        .toolkit-text-area-wrapper {
          margin-bottom: getRem(0);
        }
      }
    }
  }
}

.edit-time-record-popup {
  max-width: none;
  display: block;
  width: 95vw;
  padding-left: 0;
  padding-right: 0;

  @include breakpoint(mobile_landscape) {
    width: getRem(500);
  }

  @include breakpoint(desktop) {
    width: getRem(1000);
  }

  &-reason-disabled {
    text-align: center;
    padding-top: getRem(20);
    color: $contentAccessory;
  }


  .time-tracker-content {
    &-form {
      margin-bottom: getRem(15);
      margin-top: 0;
      justify-content: center;
      gap: getRem(10);

      @include breakpoint(desktop) {
        gap: getRem(50);
      }

      &-inputs {
        margin: 0;

        @include breakpoint(desktop) {
          width: getRem(800);
        }

        &-label {
          margin: 0;
          margin-top: getRem(15);
          padding-bottom: getRem(4);
          padding-left: getRem(10);

          @include breakpoint(mobile_landscape) {
            margin-top: getRem(23);
          }
        }

        .css-1gny415-container, .css-1p4pqme-control, .text-input {
          height: getRem(40);
          margin: 0;
        }


      }
    }

    &-calendar {
      margin: 0;
      @include breakpoint(desktop) {
        width: getRem(420);
      }
    }
  }
}


.request-vacation-popup {
  position: static;
  overflow-y: inherit;
  margin-left: 0;

  .custom-popup-card-content {
    padding-left: getRem(24);
    padding-right: getRem(24);
  
    @include breakpoint(mobile_landscape) {
      padding-left: getRem(56);
      padding-right: getRem(56);
    }
  }

  .toolkit-text-area-wrapper {
    height: getRem(90);
  }

  .rmdp-wrapper {
    overflow: auto;
    max-height: 50vh;
  }
}

.clear-log-template-link {
  @extend .custom-scrollable-table-link;
  margin-right: 0;
  display: block;
  margin-bottom: getRem(24);
  
  @include breakpoint(desktop) {
    margin-bottom: getRem(26);
  }
}

.request-overtime-popup{
  max-width: getRem(448) !important;

  &-body {
    color: #5F6C72;
    display: block;
    text-align: center;

    @include breakpoint(tablet_landscape) {
      width: getRem(334);
    }
  }

  &-button-container {
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: getRem(15);
  padding-top: getRem(32);
  }
}

.public-holiday-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: getRem(350);
  margin-top: getRem(14);
  color: $contentLeading;

    @include breakpoint(tablet) {
      width: getRem(433);
    }

    @include breakpoint(desktop) {
      position: absolute !important;
      width: getRem(433)
    }

  &-edit {
    @extend .public-holiday-div;
    margin-top: getRem(12) !important;
  }
}

.shorter-form {
  @include breakpoint(desktop) {
    width: getRem(903) !important;
  }
}

.time-off-arrow-down-icon {
  color: $secondary !important;
  width: getRem(24) !important;
  height: getRem(24) !important;
  position: absolute;
  top: getRem(-6);
  left: 47%;
}

.time-off-arrow-down-icon.expanded {
  transform: rotate(180deg);
}

.pin-tutorial-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: getRem(32);
  width: getRem(635);

  span {
    text-align: center;
    color: $contentSupporting;
  }

  video {
    width: getRem(613);
    height: getRem(350);
    border: getRem(1) solid $surfaceDivider;
  }
}

@include breakpoint(desktop) {
  .time-tracker-content-form-inputs-template-bubble:hover .time-tracker-content-form-inputs-template-remove { 
    visibility: visible;
  }
}