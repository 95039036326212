@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.performance-reviews {
    display: block;
    padding-bottom: getRem(48);
    
    &-header {
        @extend .table-base-header-type-1;
        margin-bottom: getRem(35);

        &-user {
            padding-top: getRem(24);    
        }

        &-button {
            @extend .table-base-header-type-1-button;
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;

            &-first-column {
                padding-left: getRem(32);
            }

            &-row {
                
                &-red {
                    background: $backgroundTableRowError !important;
                    outline: none !important;
                    td:last-child {
                        border-bottom-right-radius: getRem(8);
                        border-top-right-radius: getRem(8);
                        width: getRem(230);
                    }

                    td:first-child {
                        border-top-left-radius: getRem(8);
                        border-bottom-left-radius: getRem(8);
                        width: getRem(350);
                    }

                    td:nth-child(2) {
                        width: getRem(150);
                    }
                }

                &-yellow {
                    background: $backgroundTableRowHiglight !important;
                }
            }
        }
    }
}

.submitted-performance-review-span {
    &-status {
        color: $contentSuccess;
    }

    &-info {
        color: $disabled;
    }

    p{
        display: inline;
    }
}

.card-content .display-linebreak {
    overflow-wrap: break-word;
    white-space: pre-line;
}

.modify-performance-review-popup {
    position: static;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: getRem(8);
    background: $background;
    width: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: getRem(32) getRem(16);
    width: getRem(300);
    gap: getRem(32);

    &::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
    }
        
    &::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
    }
        
    &::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
    }
    
    
    @include breakpoint(mobile_landscape) {
        padding: getRem(40) getRem(56);
        width: getRem(540);
    }

    @include breakpoint(tablet_landscape) {
        width: getRem(612);
    }

    &-title {
        text-align: center;
        background: white;

        &-date {
            padding-top: getRem(3);
            color: $contentAccessory;
        }
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &-goals{
        text-align: center;
        position: relative;

        .arrow-icon {
            position: absolute;
            bottom: getRem(5);
            right: 47%;
            cursor: pointer;
            color: $secondary;
            width: getRem(24) !important;
            height: getRem(24) !important;

            &.up {
                transform: rotate(180deg);
            }

            &.down {
                transform: rotate(0deg);
            }
        }

        &-label {
            padding-left: getRem(10);
        }
        &-content {
            @extend .review-info-goals-content;
            text-align: start;
            max-height: getRem(130);
            overflow: hidden;
            margin-bottom: getRem(30);
            transition: max-height 0.3s ease;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;

            p {
                overflow-wrap: break-word !important;
            }

            &.expanded {
                max-height: none;
                display: flex;
                flex-direction: column;
            }
        }
    }

    &-wrapper {
        height: auto;
        max-height: 90vh;
        overflow-x: hidden;
        
        @include breakpoint(mobile_landscape) {
            max-height: getRem(550);
        }
    }

    .toolkit-dd-list-select__menu div { 
        z-index: 3 !important;
        max-height: getRem(200);
    }
}

.performance-review-popup-content {
    .rmdp-wrapper {
        overflow: auto;
        max-height: 42vh;
    }

    &-date{
        &-label {
            margin: 0;
            padding-left: getRem(10);
            padding-bottom: getRem(4);
            color: $contentSupporting;
        }

        &-chips {
            width: 100%;
            height: getRem(40);
            display: inline-flex;
            border: getRem(0.5) solid $contentAccessory;
            border-radius: getRem(8);
            overflow: hidden;
            margin-bottom: getRem(10);
            align-items: center;

            &-selected{
                background: $secondary !important;
                color: $onSecondary;
            }

            hr {
                background: $border;
                color: $border;
                width: getRem(1);
                height: getRem(20) !important;
            }

            button {
                align-items: center;
                width: 33.3%;
                padding: getRem(8) getRem(5);
                border: none;
                cursor: pointer;
                background: $background;               
                margin: getRem(5) getRem(6) getRem(5) getRem(6) !important;
                border-radius: getRem(8);

                &:hover {
                    background: $backgroundControls;
                }
            }

            button:last-child {
                border-right: none;
            }
        }
    }

    &-improvements {
        margin-bottom: getRem(8);
    }
}

.review-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: getRem(24);
    max-height: 90vh;
    padding: getRem(32) getRem(16);
    background: $background;
    border-radius: getRem(8);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    width: getRem(300);

    @include breakpoint(mobile_landscape) {
        width: getRem(350);
    }

    @include breakpoint(tablet) {
        width: getRem(500);
        gap: getRem(32);
        padding: getRem(40);
    }

    @include breakpoint(desktop) {
        width: getRem(612) !important;
    }

    &-date {
        color: $contentAccessory;
        margin-top: getRem(-25);
    }

    &-title {
        text-align: center;
    }

    &-goals, &-summary {
        display: flex;
        flex-direction: column;
        gap: getRem(16);
        width: 100%;

        &-content {
            color: $contentSupporting;
            h2 {
                font-size: getRem(18);
                margin-bottom: getRem(0) !important;
            }

            p, span, li, ul, ol {
                font-size: getRem(14);
                margin-bottom: getRem(0);
                overflow-wrap: break-word;
            }

            li {
                &::marker {
                    font-weight: 600 !important;
                }
            }

            a {
                text-decoration: underline;
                color: $textEditorLink;
            }
        }

        &-title {
            text-align: center;
        }
    }

    &-goals {
        &-label {
            margin: 0;
            color: $contentSupporting;
        }
    }

    &-close {
        position: absolute;
        right: getRem(20);
        top: getRem(16);
        color: $disabled;
        height: getRem(32);
        width: getRem(32);

        &:hover {
            color: $secondary;
            cursor: pointer;
        }
    }

    .popup-bottom-button-container {
        padding-top: 0 !important;
    }

    &::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
    }
      
    &::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
    }
      
    &::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
    }
}

.html-collapsed {
    align-items: center;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    width: fit-content !important;

    display: inline-block;
    max-width: getRem(500);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    p {
        margin: 0 !important;
        padding-right: getRem(8);   
    }

    ul, ol {
        display: flex;
        margin: 0;   
        padding-left: getRem(2);
    }

    li {
        &::marker {
            font-weight: 600 !important;
        }
    }

    h2 {
        font-size: 18px;
        margin: 0;
    }    
    .inner {
        display: inline;
        @extend .t-b2;
        text-decoration: none;

        br {
            display: none !important;
        }
    }
    .inner > * {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        br {
            display: none !important;
        }
    }
}