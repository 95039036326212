@import "../../styles/index.scss";

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.newsoft-logo-loader {
    height: getRem(200);
    animation-name: logo-loader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes logo-loader {
    0% {
        height: 10vh;
        opacity: 0.1;
    }
    50% {
        height: 16vh;
        opacity: 1;
    }
    100% {
        height: 10vh;
        opacity: 0.1;
    }
}
