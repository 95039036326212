@import "../../styles/index.scss";


.checkbox {
  background-color: $surface;
  border: getRem(0.5) solid $border;
  position: absolute;
  width: getRem(16);
  height: getRem(16);
  left: 0;
  top: 0;
  border-radius: getRem(3);

  &.checkbox-locked {
    border: getRem(0.5) solid $border !important;
  }

  &::after {
    content: "";
    display: none;
    background: url("../../assets/images/checkMark.svg") no-repeat center center;
    background-size: getRem(10.29) getRem(7.88);
    width: 100%;
    height: 100%;
    margin-left: getRem(0.4);
  }

  &-container {
    display: block;
    position: relative;
    color: $contentSupporting !important;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: getRem(24);
    line-height: getRem(20) !important;

    input {
      display: none !important;
    }

    &-label {
      cursor: pointer !important;
    }

    &.locked {
      pointer-events: none;
      opacity: 0.8;
    }
  }
}

input:checked {
  ~ .checkbox {
    border: getRem(0.5) solid $secondary;
  }

  ~ .checkbox:after {
    display: block;
  }
}

.arrow-down-icon {
  color: $contentSupporting !important;
  width: getRem(16) !important;
  height: getRem(16) !important;
  position: absolute;
  top: getRem(2);
  right: 0;
  cursor: pointer;
}

.arrow-down-icon.expanded {
  transform: rotate(180deg);
  color: $secondary !important;
}

.description-div {
  color: $contentSupporting !important;
  display: flex;
  width: getRem(262);
  height: max-content;
  margin-top: getRem(2);
  align-items: flex-start;
  padding-left: getRem(24);
  flex-direction: column;

  ul {
    padding-left: getRem(16);
    margin-bottom: getRem(10);
    color: $contentSupporting !important;
  }

  &-list-element {
    margin-bottom: 0;
  }

  &-list-title {
    margin: getRem(8) 0 0 0;
  }

  &-paragraph {
    margin-bottom: 0;
  }

  &-link {
    color: $secondary !important;
    text-decoration: underline;
    margin-top: getRem(5) !important;

    &:hover {
      color: $secondary !important;
    }
  }
}

.label-expanded {
  color: $secondary !important;
}
