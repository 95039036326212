@import '../../styles/index.scss';


.single-date-input {

  display: flex;
  flex-direction: column;
  min-width: 14rem;

  &-label {
    margin: 0;
    color: $blackTransparent;
    font-weight: 600;
    padding-left: getRem(8);
  }

  &-wrapper {
    margin-bottom: getRem(14);

    &-label {
      position: relative;
      width: 100%;
      margin: 0;
    }


    .rmdp-container {
      width: 100%;

      svg {
        stroke: $secondary;
        width: getRem(35);
        height: getRem(28);
        margin-right: getRem(5);
        padding-left: getRem(5);
        border-left: getRem(0.5) solid $disabled;
      }
    }

    .rmdp-wrapper {
      .rmdp-time-picker {
        display: flex;
        gap: 1rem;
        justify-content: center;

        div {
          flex: none
        }

        input {
          width: 3rem;
          margin: 2px;
          line-height: 0;
          font-size: getRem(14);

          // Here we are replacing outline with transparent border and this way make input always the same size
          border: 2px solid transparent;
          &:focus-visible {
            border: 2px solid $contentAccessory;
            border-radius: 4px;
            outline: none;
          }

        }
      }

      .rmdp-arrow-container {
        width: 15px;
        height: 15px;

        &:hover {
          color: $onSecondary !important;
          background: none !important;
          border: none !important;
          box-shadow: none !important;
        }

      }

      .rmdp-down {
        transform: translateY(-5px)
      }

      .rmdp-up, .rmdp-down {
        .rmdp-arrow {
          width: 10px;
          height: 10px;
          border: solid $contentAccessory;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }
}
