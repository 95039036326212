@import '../../styles/index.scss';


.toolkit-dd-filter {
  display: flex;
  align-items: center;
  gap: getRem(8);
  margin-left: getRem(4);
  overflow-y: visible;

  &-label {
    color: $contentAccessory;
  }

  &-react-select {
    &__control {
      box-shadow: none !important;
      cursor: pointer !important;
      border-radius: getRem(80) !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }

    &__indicator {
      color: $secondary !important;
      width: getRem(22) !important;
      height: getRem(22) !important;
      display: flex !important;
      align-items: center;
      padding: 0 !important;
    }

    &__indicator:hover {
      color: $backgroundControls !important;
    }

    &__menu {
      position: absolute !important;
      right: 0 !important;
      width: getRem(147) !important;
      border-radius: getRem(8) !important;
      box-shadow: $shadowDropdown !important;
      cursor: pointer !important;
      z-index: 1000 !important;
    }

    &__option {
      @extend .t-s4;
      font-size: getRem(14) !important;
      font-weight: 400 !important;
      color: $contentSupporting !important;
      background-color: $background !important;
      cursor: pointer !important;

      &:first-child {
        margin-top: getRem(6) !important;
      }

      &:last-child {
        margin-bottom: getRem(6) !important;
      }

      &--is-selected {
        @extend .t-s3;
        font-size: getRem(14) !important;
        font-weight: 600 !important;
        color: $contentHighlightPrimary !important;
      }
    }

    &__option:hover {
      background-color: $backgroundControls !important;
    }
  }
}
