@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.employees {
    padding-top: getRem(24);
    padding-bottom: getRem(48);

    &-header {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: right;
        padding-left: getRem(16);
        padding-right: getRem(16);

        @include breakpoint(mobile_landscape) {
            flex-direction: row;
            justify-content: space-between;
            padding-left: getRem(25);
            padding-right: getRem(25);
        }

        @include breakpoint(desktop) {
            padding-bottom: getRem(24);
        }

        &-right {
            text-align: right;
            display: inline-block;
            width: 100%;

            @include breakpoint(tablet_landscape){
                width: auto;
            }

            &-draft {
                background-color: white;
                display: flex;
                width: auto;
                color: $contentActionPrimary;
                padding-right: getRem(54);
                flex-direction: column;
                justify-content: flex-end;
                text-decoration: underline;
            
                img {
                    position: relative;
                    top: getRem(-12);
                    right: getRem(-6);
                    width: getRem(8);
                    height: getRem(9);
                }
            }

            &-add {
                display: flex;
                float: right;

                &-button {
                    display: flex;
                    margin-top: getRem(24);
                    background-color: $secondary;
                    color: $onSecondary;
                    border-radius: getRem(8);
                    border: 0;
    
                    @include breakpoint(mobile_landscape) {
                        margin-top: getRem(7);
                    }   
                }

                &-draft {
                    position: relative;
                    top: getRem(-3);
                    display: flex;
                    width: auto;
                    color: $contentActionPrimary;
                    padding-right: getRem(54);
                    flex-direction: column;
                    justify-content: flex-end;
                    text-decoration: underline;
                
                    img {
                        position: relative;
                        top: getRem(-12);
                        right: getRem(-6);
                        width: getRem(8);
                        height: getRem(9);
                    }
                }
            }
            &-filters {
                margin-top: getRem(85);
                float: left;

                @include breakpoint(mobile_landscape) {
                    float: right;
                    margin-right: getRem(20);
                    margin-top: getRem(12);
                }

                @include breakpoint(desktop) {
                    margin-right: getRem(81);
                }

                &-filter {
                    background: none;
                    color: inherit;
                    border: none;
                    cursor: pointer;
                    outline: inherit;
                    margin-right: getRem(13);
                    @include breakpoint(desktop){
                        margin-left: getRem(15);
                    }
                }

                &-filter:first-child {
                    padding-left: 0;
                }

                .employees-selected-option {
                    text-decoration: underline;
                    text-decoration-color: $secondary;
                }
            }
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;
        }
    }
}

.employee-info-popup {
    min-width: getRem(343);
    width: fit-content;
    padding-top: getRem(24);
    padding-bottom: getRem(40);

    .custom-popup-card-title {
        margin: 0;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: getRem(16);

        &-row {
            display: flex;
            flex-direction: column;
            gap: getRem(3);
        }

        &-label {
            @extend .t-h3;
            color: $contentLeading;
        }

        &-data {
            @extend .t-s4;
            word-break: break-all;
            color: $contentSupporting;
        }
    }

    &-header {
        text-align: center;
        margin-bottom: getRem(32);
        
        img {
            height: getRem(128);
            width: getRem(128);
            border-radius: getRem(16);
            margin-bottom: getRem(16);
        }

        &-name {
            color: $contentLeading;
            margin-bottom: getRem(6);
        }

        &-job-position {
            color: $contentSupporting;
        }
    }
}

.add-employee-popup {
    .toolkit-dd-list-select__menu div {
        max-height: getRem(100);

        * {
            max-height: getRem(100);
        }
    }

    .toolkit-dd-list-wrapper {
        margin-bottom: getRem(10) !important;
    }

    .toolkit-slider-input-wrapper {
        margin-bottom: getRem(15) !important;
    }

    .single-date-input-wrapper {
        margin-bottom: getRem(10) !important;
    }

    .single-date-input-label {
        margin-top: getRem(18) !important;
    }

    &-checkbox-wrapper {
        display: flex;
        flex-direction: column;
        gap: getRem(15);

        label {
            color: $contentSupporting !important;
        }
    }
}

.show-password {
    text-decoration: underline;
    position: absolute;
    right: getRem(0);
    bottom: getRem(-20);
    cursor: pointer;
    z-index: 5;
}

.email-div {
    position: relative;
}

.password-div {
    display: inline;
    position: relative;

    input{
        padding-right: getRem(40);
    }

    &-content-password-visible{
        height: getRem(69);
        width: getRem(25);
        margin-left: getRem(240);
        cursor: pointer;
        display: flex;
        color: $eyePasswordIcon;
        position: absolute;
        top: getRem(33);
        right: getRem(9);

        @include breakpoint (mobile_landscape){
            margin-left: getRem(333);
        }
    }
}

.onboarding-label {
    width: getRem(71);
    height: getRem(16);
    background-color: $backgroundError;
    color: white;
    position: absolute;
    border-radius: getRem(3);
    right: getRem(16);
    top: getRem(-2);
    padding: getRem(2) getRem(8);
    padding-bottom: getRem(3);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.onboarding-label-td {
    position: relative;
}