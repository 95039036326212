@import '../../styles/index.scss';
@import '../../styles/common.scss';

.multiple-select {
    position: relative;
    width: max-content;
    display: flex;
    margin-left: getRem(-10);
    margin-bottom: getRem(-3);

    &-button {
        display: flex;
        width: max-content;
        height: min-content;
        justify-content: center;
        align-items: flex-start;
        gap: getRem(6);
        border: none;
        background: none;
    }

    &-content {
        position: absolute;
        background-color: $background;
        width: getRem(360);
        height: max-content;
        overflow: auto;
        z-index: 1;
        box-shadow: $shadowDropdown;
        border-radius: 10px;
        top: 100%;
        left: getRem(-75);
        justify-items: left;
        padding-bottom: getRem(16);
        padding-top: getRem(16);
        max-height: getRem(224);

        &::-webkit-scrollbar {
            @extend .custom-scroll-webkit-scrollbar;
        }

        &::-webkit-scrollbar-track {
            @extend .custom-scroll-webkit-scrollbar-track;
        }

        &::-webkit-scrollbar-thumb {
            @extend .custom-scroll-webkit-scrollbar-thumb;
        }
    }

    &-item {
        padding: getRem(12) getRem(16);
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
        height: getRem(32);
    }

    &-item:hover {
        cursor: pointer;
        background-color: $calendarDefaultDayAlt;
    }

    &-checkmark {
        color: $secondary;
        margin-right: getRem(16);
        width: getRem(16);
        height: getRem(16);
    }

    &-checkmark-unselected {
        color: $contentAccessory;
        margin-right: getRem(16);
        width: getRem(16);
        height: getRem(16);
    }

    &-icon {
        color: $secondary;
        position: relative;
        margin-left: getRem(-4);
        margin-top: getRem(-3);
    }

    &-text {
        color: $contentSupporting;
        margin-right: getRem(4);
        max-width: getRem(232);
        height: getRem(20);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &-reset {
        margin-top: getRem(3.5) !important;
        display: inline;
        color: $secondary !important;
        text-decoration: underline !important;
        cursor: pointer;
    }
}


