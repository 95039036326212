%t-shared {
  font-family: "Gilroy", serif;
  line-height: normal;
}

// class 't-h1' - h1
.t-h1 {
  @extend %t-shared;
  font-size: getRem(30);
  font-weight: 600;
}

// class 't-h2' - h2
.t-h2 {
  @extend %t-shared;
  font-size: getRem(24);
  font-weight: 600;
}

// class 't-h3' - h3
.t-h3 {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 600;
  text-transform: uppercase;
}

// class 't-h4' - h4
.t-h4 {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 600;
}

// class 't-h5' - h5
.t-h5 {
  @extend %t-shared;
  font-size: getRem(40);
  font-weight: 600;
  text-transform: uppercase;
}

// class 't-s1' - subtitle 1
.t-s1 {
  @extend %t-shared;
  font-size: getRem(18);
  font-weight: 600;
}

// class 't-s2' - subtitle 2
.t-s2 {
  @extend %t-shared;
  font-size: getRem(18);
  font-weight: 400;
}

// class 't-s3' - subtitle 3
.t-s3 {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 600;
}

// class 't-s4' - subtitle 4
.t-s4 {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 400;
}

// class 't-s5' - subtitle 5
.t-s5 {
  @extend %t-shared;
  font-size: getRem(28);
  font-weight: 600;
  text-transform: uppercase;
}

// class 't-s6' - subtitle 6
.t-s6 {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
}

// class 't-b1' - body 1
.t-b1 {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 600;
}

// class 't-b2' - body 2
.t-b2 {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 400;
}

// class 't-b3' - body 3
.t-b3 {
  @extend %t-shared;
  font-size: getRem(10);
  font-weight: 600;
}

// class 't-b4' - body 4
.t-b4 {
  @extend %t-shared;
  font-size: getRem(10);
  font-weight: 400;
}

// class 't-b5' - body 5
.t-b5 {
  @extend %t-shared;
  font-size: getRem(18);
  font-weight: 500;
  line-height: 130%;
}

// class 't-button' - button
.t-button {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 600;
  text-transform: uppercase;
}

// class 't-button-underline' - button underline
.t-button-underline {
  @extend %t-shared;
  font-size: getRem(14);
  font-weight: 500;
  line-height: 130%;
  text-decoration: underline;
}

// class 't-banner' - banner
.t-banner {
  font-family: "NewYorkExtraLarge", serif;
  line-height: normal;
}
