@import '../../styles/index.scss';


.rmdp-shadow {
    box-shadow: $shadowInfoCard !important;
}

.rmdp-ep-shadow:after {
    box-shadow: 0 0 0.625rem rgba(30,30,30,.1) !important;
}

.rmdp-wrapper {
    background-color: $surface;
    border-radius: getRem(8);

    .rmdp-arrow-container {
        height: getRem(12);
        width: getRem(7);
        
        .rmdp-arrow {
            border: solid $contentAccessory;
            border-width: 0 2px 2px 0;
            display: inline-block;
            height: getRem(12);
            margin-top: 5px;
            padding: 2px;
            width: getRem(7);
        }
    }

    .rmdp-range {
        background-color: transparent;
        border: 0;
        box-shadow: none;

        span {
            box-shadow: $shadowSelectedCalendarDate;
            color: $onSecondary !important;
            background: $calendarDayHover !important;

            &:first-child {
                border-bottom-left-radius: 50%;
                border-top-left-radius: 50%;
            }
        }
    }

    .rmdp-range-picker-footer {
        font-size: getRem(14);
        font-weight: 600;

        .rmdp-week-day {
            @extend .t-s3;
            color: $contentLeading;
        }

        button {
            margin: getRem(1) auto;
            width: getRem(113);
            background-color: transparent;
            border: getRem(1) solid $secondary;
            color: $secondary;
            &:hover {
                background-color: $secondary;
                color: $onSecondary;
            }
        }
    }

    .rmdp-range-picker-footer > div {
        flex-direction: column;
    }

    .rmdp-header-plugin {
        color: $contentLeading;
        background: $surface;

        &.right.small {
            width: getRem(113);
        }
        .rmdp-hp-dd {
            font-size: getRem(30);
            font-weight: 600;
        }
        .rmdp-hp-dddd {
            font-size: getRem(14);
            font-weight: 600;
        }
        .rmdp-hp-my{
            font-size: getRem(14);
            font-weight: 600;
        }
    }

    .rmdp-toolbar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: getRem(72);
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: getRem(14);
            font-weight: 600;
            width: getRem(109);
            height: getRem(33);
            color: $secondary;
            background-color: transparent;
            border: getRem(1) solid $secondary;
            border-radius: getRem(8);
            &:hover {
                color: $secondaryVariant;
                border: getRem(1) solid $secondaryVariant;
                box-shadow: none;
            }
        }
    }
    
    .single-date-input-label {
        margin-top: getRem(20);
    }

    .rmdp-calendar {
        padding-top: getRem(16);
        padding-bottom: getRem(16);
        padding-left: 0;
        padding-right: 0;

        .rmdp-day-picker {
            padding: 0;

            > div {
                display: flex;
                flex-direction: column;
                gap: getRem(6);
            }
        }

        .rmdp-header {
            margin-bottom: getRem(18);

            &-values span {
                @extend .t-s3;
                color: $contentLeading;
            }

            .cursor-pointer {
                cursor: pointer;
                position: relative;
                top: getRem(0);
                color: $contentAccessory;

                &-left, &-right {
                    width: getRem(24);
                    height: getRem(24);
                    border: none;
                    fill: $secondary;
                }
                &-left {
                    margin-left: getRem(24.7);
                }
                &-right {
                    margin-right: getRem(24.7);
                    -webkit-transform:rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }
        
        .rmdp-week {
            gap: getRem(6);
            margin-left: getRem(16);
            margin-right: getRem(16);

            .rmdp-day.rmdp-today span {
                background: $background;
                color: $contentSupporting;
            }

            &-day {
                @extend .t-b1;
                width: getRem(36);
                height: getRem(36);
                color: $contentHighlightPrimary;
            }
        }

        .rmdp-week:has(.rmdp-day.rmdp-deactive) {
            &:not(:has(.rmdp-day:not(.rmdp-deactive))) {
                display: none;
            }
        }

        .rmdp-day {
            height: getRem(36);
            width: getRem(36);

            span {
                @extend .t-b1;
                position: static;
                height: getRem(36);
                width: getRem(36);
            }

            .highlight-gray {
                @extend .t-b2;
                background: $calendarDefaultDay;
                color: $contentSupporting !important;

                &:hover {
                    background: $calendarDayHover;
                    color: $onSecondary !important;
                }
            }
            .rmdp-default {
                background: $calendarDefaultDay;
                color: $contentSupporting;

                &:hover {
                    color: $onSecondary !important;
                    background: $calendarDayHover !important;
                }
            }

            .rmdp-selected {
                box-shadow: $shadowSelectedCalendarDate;
                background: $calendarDayHover !important;
                color: $onSecondary !important;
            }

            .rmdp-today {
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent;
                    color: $primary;
                }
            }
            .rmdp-approved-unpaid-day-off {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-requested-unpaid-day-off {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-declined-unpaid-day-off {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-approved-illness {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-requested-illness {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-declined-illness {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-public-day-off {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-approved-day-off {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-requested-day-off {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }
            .rmdp-declined-day-off {
                color: $contentSupporting;
                box-shadow: $shadowSelectedCalendarDate;

                &:hover {
                    background: transparent !important;
                    color: $primary !important;
                }
            }

            .rmdp-logged-base {
                position: absolute;
                border-radius: 50%;
                width: getRem(4.5);
                height: getRem(4.5);
                left: getRem(16);
                bottom: getRem(3);
            }

            .rmdp-logged {
                @extend .rmdp-logged-base;
                background-color: $calendarLoggedDay;
            }

            .rmdp-partially-logged {
                @extend .rmdp-logged-base;
                background-color: $calendarPartiallyLoggedDay;
            }

            .rmdp-in-review {
                @extend .rmdp-logged-base;
                background-color: $calendarInReviewDay;
            }

            &-hidden span:hover {
                background-color: transparent;
            }
        }

        .rmdp-day.rmdp-deactive {
            .rmdp-logged {
                background-color: #CDE9C5;
            }

            .rmdp-partially-logged {
                background-color: #F3F3F3;
            }
        }

        .rmdp-day.rmdp-disabled {
            color: $contentSupporting !important;
        }

        .rmdp-day.rmdp-deactive > span {
            @extend .t-b2;
            color: $contentAccessory !important;
            background-color: $calendarDefaultDay;

            div {
                color: $contentAccessory;
            }

            &:hover {
                color: $onSecondary;
                background-color: $calendarDayHover;
            }
        }

        .rmdp-day.rmdp-deactive.rmdp-selected > span,
        .rmdp-day.rmdp-range > span,
        .rmdp-day.rmdp-deactive.rmdp-range > span {
            color: $onSecondary !important;

            &:hover {
                color: $onSecondary !important;
                background: $calendarDayHover !important;
            }

            div {
                color: $onSecondary;

                &:hover {
                    color: $onSecondary !important;
                    background: transparent;
                }
            }
        }

        .rmdp-day.rmdp-deactive > .rmdp-default {
            &:hover {
                div {
                    color: $onSecondary !important;
                }
            }
        }


        .rmdp-day.rmdp-deactive.rmdp-range > span {
            box-shadow: $shadowSelectedCalendarDate;

            &:first-child {
                border-bottom-left-radius: 50%;
                border-top-left-radius: 50%;
            }
        }

        .rmdp-month-year-picker-base {
            .rmdp-ym {                
                .rmdp-day {
                    display: flex;
                    justify-content: center;
                    width: getRem(85);
                    height: getRem(36);

                    span {
                        @extend .t-b1;
                        width: getRem(85);
                        height: getRem(36);
                        color: $contentSupporting;
                    }
                }

                .rmdp-day.rmdp-selected span {
                    color: $onSecondary !important;
                }
            }

            .rmdp-day span {
                &:hover {
                    background: $calendarDefaultDayAlt;
                    color: $contentSupporting;
                }
            }
            
            .rmdp-selected span {
                background: $calendarDayHover !important;
                color: $onSecondary;
                box-shadow: $shadowSelectedCalendarDate;
            }

            .rmdp-today span {
                background: $surface;
            }
        }

        .rmdp-month-picker {
            @extend .rmdp-month-year-picker-base;
            height: 105%;
        }
        
        .only.rmdp-month-picker {
            display: flex !important;
            flex-direction: column;
            gap: getRem(16);
            width: fit-content;
            height: fit-content;
            padding-left: getRem(16);
            padding-right: getRem(16);
            padding-bottom: getRem(13);
            padding-top: getRem(10);

            @include breakpoint(desktop) {
                gap: getRem(32);
            }

            .rmdp-ym {
                gap: getRem(7);

                @include breakpoint(mobile_landscape) {
                    gap: getRem(15);
                }

                @include breakpoint(desktop) {
                    gap: getRem(31);
                }
            }
        }

        .rmdp-year-picker {
            @extend .rmdp-month-year-picker-base;
            height: 105%;
        }
    }
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    box-shadow: none;
    background-color: $onSecondary !important;
    color: $contentSupporting !important;
}

.rmdp-input {
    @extend .t-s4;

    color: $contentSupporting;
    border: getRem(0.5) solid $border !important;
    padding-left: getRem(12) !important;
    padding-right: getRem(12) !important;
    height: getRem(40) !important;
    width: 100%;
    border-radius: getRem(8);
}

.single-date-input {
    &-label {
        margin: 0;
        padding-left: getRem(10);
        padding-bottom: getRem(4);
        color: $contentSupporting;
    }
    
    &-wrapper {
        margin-bottom: getRem(14);
        &-label {
            position: relative;
            width: 100%;
            margin: 0;
        }


        .rmdp-container {
            width: 100%;
            svg {
                stroke: $secondary;
                width: getRem(35);
                height: getRem(28);
                margin-right: getRem(5);
                padding-left: getRem(5);
                border-left: getRem(0.5) solid $disabled;
            }
        }
    }
}

.range-date-input {
    &-label {
        @extend .single-date-input-label;
    }
    
    &-wrapper {
        @extend .single-date-input-wrapper;

        &-label {
            width: 100%;
            margin: 0;
        }

        .rmdp-container {
            width: 100%;

            svg {
                stroke: $secondary;
                width: getRem(35);
                height: getRem(28);
                margin-right: getRem(5);
                padding-left: getRem(5);
                border-left: getRem(0.5) solid $disabled;
            }
        }

        .rmdp-range-picker-footer button {
            margin: getRem(15) auto;

            @include breakpoint(mobile_landscape) {
                margin: getRem(20) auto;
            }
        }
    }
}

.single-datepicker-field {
    @extend .t-s4;
    color: $contentSupporting;
    text-align: left;
    height: getRem(40);
    width: 100%;
    background: $surface;
    outline: none;
    border: getRem(0.5) solid $contentAccessory;
    padding-left: getRem(15);
    display: block;
    padding-right: getRem(6);
    border-radius: getRem(8);
    &::-webkit-calendar-picker-indicator {
        padding-left: getRem(5);
        font-size: getRem(20);
        color: $secondary;       
        border-left: getRem(0.5) solid $contentAccessory;
    }
}

.validation-error {
    color: $contentError;
}

.datepicker-plugin {
    width: getRem(113);
    display: flex;
    flex-direction: column;
    padding-top: getRem(22);
    padding-bottom: getRem(24);

    &-type {
        color: $contentAccessory;
        height: getRem(40);
        margin: auto;
        margin-top: 0;
        padding-left: getRem(15);
        padding-right: getRem(15);
    }

    &-weekday {
        color: $contentLeading;
        margin: auto;
    }

    &-day {
        color: $contentLeading;
        margin: 0 auto 0 auto;
    }

    &-date {
        color: $contentLeading;
        margin: auto;
    }
}

.range-datepicker-plugin {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: getRem(15);
    padding-bottom: getRem(15);

    @include breakpoint(mobile_landscape) {
        flex-direction: column;
        width: getRem(113);
        padding-top: getRem(22);
        padding-bottom: getRem(24);
    }

    &-type {
        color: $contentAccessory;
        max-width: getRem(60) !important;

        @include breakpoint(mobile_landscape) {
            display: block;
        }
    }

    &-range {
        text-align: center;
        margin: auto;
        display: flex;
        justify-content: center;

        &-empty {
            opacity: 0;
            background-color: $surface !important;
            cursor: default !important;
        }

        &-default {
            color: $contentAccessory;
        }

        > div {
            width: getRem(113);
            text-align: left;
            color: $contentSupporting;

            @include breakpoint(tablet_landscape) {
                padding-left: getRem(28);
            }

            img {
                width: getRem(14);
                height: getRem(14);
                margin-right: getRem(4);
                cursor: pointer;
            }
        }

        > span {
            display: block;
        }
    }

    button {
        margin-left: auto;
        margin-right: auto;
    }
}

.calendar-tooltip {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #1E1E1E;
    color: $onSecondary;
    box-shadow: 0 0 getRem(5) #ccc;
    border-radius: getRem(6);
    padding: getRem(6) getRem(6);
    font-size: getRem(8);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.calendar-tooltip::after {
    content: '';
    position: absolute;
    bottom: getRem(-4);
    left: 50%;
    z-index: 10000;
    transform: translateX(-50%);
    border-width: getRem(5) getRem(5) 0;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.hat {
    width: 100%;
    height: 20%;
    position: absolute;
    top: 0;
    border-top-right-radius: getRem(4);
    border-top-left-radius: getRem(4);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 0.3s ease, background-color 0.3s ease, border-radius 0.3s ease;

    &.selected {
        height: 100%;
        border-radius: getRem(4);
    }
}
