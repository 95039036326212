@import "../../styles/index.scss";


.checkbox {
    background-color: $surface;
    border: getRem(0.5) solid $border;
    position: absolute;
    width: getRem(16);
    height: getRem(16);
    left: 0;
    border-radius: getRem(3);

    &::after {
        content: "";
        display: none;
        background: url("../../assets/images/checkMark.svg") no-repeat center center;
        background-size: getRem(10.29) getRem(7.88);
        width: 100%;
        height: 100%;
        margin-left: getRem(0.4);
    }

    &-container {
        display: block;
        position: relative;
        color: $contentSupporting;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-left: getRem(32);
        line-height: getRem(20) !important;

        > input {
            display: none;
        }
    }
}

input:checked {
    ~ .checkbox {
        border: getRem(0.5) solid $secondary;
    }

    ~ .checkbox:after {
        display: block;
    }
}
