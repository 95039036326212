@import '../../styles/index.scss';
@import '../../pages/Roles/index.scss';


.employees-box-popup {
    &-wrapper {
        @extend .roles-popup-box;
        height: getRem(232);
    }

    &-table {
        @extend .roles-popup-table;
        height: 100%;
        padding: 0;
        overflow-y: auto;        
    }

    &-row {
        @extend .roles-popup-table-row;
        overflow-x: clip;
        text-overflow: ellipsis;

        &:hover {
            background-color: $backgroundControls;
            color: $contentLeading;
        }

        span {
            padding: 0 getRem(15) 0 getRem(16);
        }

        &-img {
            display: inline-block;
            padding-left: getRem(16);

            img {
                width: getRem(24);
                height: getRem(24);
                border-radius: getRem(4);
                margin-right: getRem(-8);
            }
        }
    }
}