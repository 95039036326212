@import '../../styles/index.scss';


.create-product {
  display: flex;
  padding-bottom: getRem(48);

  &-left {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: getRem(32);
    margin: getRem(24);

    &-details {
      display: flex;
      width: getRem(940);
      padding: getRem(32) getRem(24);
      flex-direction: column;
      align-items: center;
      gap: getRem(20);
      border-radius: getRem(8);
      box-shadow: $shadowInfoCard;

      &-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: getRem(8);
        align-self: stretch;

        &-title {
          color: $contentLeading;
          margin-bottom: getRem(8);
        }

        &-description {
          margin-bottom: getRem(5);

          textarea {
            height: getRem(100);
          }
        }

        div {
          width: 100%;
        }

        &-inputs {
          display: flex;
          align-items: flex-start;
          gap: getRem(16);
          align-self: stretch;

          &-price {
            position: relative;

            svg {
              width: getRem(24);
              height: getRem(24);
              position: absolute;
              right: getRem(9);
              top: getRem(34);
            }

            &::before {
              content: '';
              position: absolute;
              right: getRem(27.5);
              top: getRem(45);
              rotate: 90deg;
              border-top: getRem(0.08) solid rgba(1, 33, 56, 0.4);
              width: getRem(26);
            }

            input[type='number']::-webkit-outer-spin-button,
            input[type='number']::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    &-attributes {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: getRem(8);

      &-title {
        margin-top: getRem(-8);
        width: 100%;
        color: $contentLeading;
        margin-bottom: getRem(8);
      }

      &-inputs {
        display: flex;
        align-items: flex-start;
        gap: getRem(16);
        align-self: stretch;
        position: relative;

        div {
            width: 100%;
        }

        &-remove{
          &-name {
            position: absolute;
            top: getRem(14);
            left: 48%;
            cursor: pointer;
            width: getRem(16) !important;
            height: getRem(16) !important;
            color: $disabled;

            &:hover {
              color: $secondary;
            }
          }
        }
      }
    }

    &-options {
      display: flex;
      width: getRem(940);
      padding: getRem(32) getRem(24);
      flex-direction: column;
      align-items: center;
      gap: getRem(24);
      border-radius: getRem(8);
      box-shadow: $shadowInfoCard;
      position: relative;

      .arrow-down-icon {
        color: $secondary !important;
        width: getRem(24) !important;
        height: getRem(24) !important;
        position: absolute;
        top: getRem(24);
        right: getRem(24);
        cursor: pointer;
      }

      .arrow-down-icon.expanded {
        transform: rotate(180deg);
      }

      &-inputs {
        display: flex;
        align-items: flex-start;
        gap: getRem(16);
        align-self: stretch;

        &-name {
          position: relative;

          span {
            position: absolute;
            text-align: right;
            right: 0;
            cursor: pointer;
            bottom: getRem(-8);
            font-size: getRem(12);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            color: $contentSupporting;
          }
        }

        &-type {
          position: absolute;
          top: getRem(34);
          right: getRem(8);
          display: flex;
          height: getRem(24);
          justify-content: flex-end;
          align-items: center;
          gap: getRem(8);
          width: fit-content !important;

          .active {
            color: $secondary;
          }

          svg {
            cursor: pointer;
            color: $disabled;

            &:hover {
              color: $secondary;
            }
          }
        }

        &-value {
          display: flex;
          flex-direction: column;
          gap: getRem(4);
          position: relative;

          &-item {
            position: relative;

            &:first-child {
              padding-bottom: getRem(6);
            }
          }

          &-label {
            position: absolute;
            text-align: right;
            right: 0;
            cursor: pointer;
            bottom: getRem(-8);
            color: $contentSupporting;

            &.active {
              color: $secondary !important;
            }

            &.inactive {
              color: $contentSupporting !important;
            }
          }
        }

        div {
          width: 100%;
        }

        &-remove{
          &-name {
            position: absolute;
            top: getRem(14);
            right: -1%;
            cursor: pointer;
            width: getRem(16) !important;
            height: getRem(16) !important;
            color: $disabled;

            &:hover {
              color: $secondary;
            }
          }

          &-value {
            position: absolute;
            bottom: getRem(49);
            right: -1.5%;
            cursor: pointer;
            width: getRem(16) !important;
            height: getRem(16) !important;
            color: $disabled;

            &:hover {
              color: $secondary;
            }
          }
        }
      }

      &-title {
        width: 100%;
        display: flex;
        align-items: flex-start;
        text-align: left;
      }

      &-folded {
        text-align: left;
        width: 100%;
        margin-top: getRem(-16);
      }
    }
  }

  &-right {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: getRem(32);
    margin: getRem(24) 0;

    &-item {
      display: flex;
      width: getRem(341);
      padding: getRem(24);
      flex-direction: column;
      align-items: flex-start;
      gap: getRem(12);
      border-radius: getRem(8);
      box-shadow: $shadowInfoCard;

      div {
        width: 100%;
      }

      &-public {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .active {
          cursor: pointer;
          color: $secondary;
        }

        .inactive {
          cursor: pointer;
          color: $disabled;
        }
      }
    }

    &-main-image {
      display: flex;
      padding: getRem(24);
      flex-direction: column;
      align-items: flex-start;
      gap: getRem(16);
      align-self: stretch;
      border-radius: getRem(8);
      box-shadow: $shadowInfoCard;
      position: relative;
      overflow: hidden;

      img {
        cursor: pointer;
        height: getRem(293);
        width: getRem(293);
        border-radius: getRem(4);
        object-fit: cover;
      }

      &-remove {
        width: getRem(24);
        height: getRem(24);
        color: $secondary;
        cursor: pointer;
        position: absolute;
        right: getRem(15);
        top: getRem(45);
        background: $background;
        border-radius: 50%;
      }
    }

    &-gallery {
      @extend .create-product-right-main-image;

      &-images {
        display: flex;
        align-items: flex-start;
        gap: getRem(10);
        max-width: getRem(293) !important;
        height: auto;
        flex-wrap: wrap;
        object-fit: cover;

        &-item {
          position: relative;
          display: inline-block;
        }

        &-remove {
          width: getRem(14) !important;
          height: getRem(14) !important;
          color: $secondary;
          cursor: pointer;
          position: absolute;
          top: getRem(-8);
          right: getRem(-8);
          background: $background;
          border-radius: 50%;
        }
      }

      img {
          position: relative;
          height: getRem(65);
          width: getRem(65);
          border-radius: getRem(4);
      }
    }
  }

  &-loading {
    position: fixed;
    top: 50%;
    left: 47%;
  }
}

.add-option-details-popup {

  &-image {
    position: relative;

    img {
      height: getRem(231);
      width: 100%;
      border-radius: getRem(8);
      margin-bottom: getRem(16);
      margin-top: getRem(8);
      object-fit: contain;
      object-position: center;
      border: getRem(0.5) solid transparent;
      cursor: pointer;

      &.uploaded {
        border-color: $border;
      }
    }

    &-remove {
      width: getRem(24);
      height: getRem(24);
      color: $secondary;
      cursor: pointer;
      position: absolute;
      right: getRem(-10);
      top: getRem(-5);
      background: $background;
      border-radius: 50%;
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: getRem(24);
  }
}