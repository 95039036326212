@import "../../styles/index.scss";

.counter {
  display: flex;
  align-items: center;

  .counter-button {
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: getRem(24);
    color: $contentLeading;
    cursor: pointer;
    padding: getRem(8) 0;
  }

  .counter-display {
    display: flex;
    padding: getRem(10) getRem(13);
    margin: 0 getRem(8);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: getRem(8);
    border: getRem(0.5) solid $blackTransparent;
    width: getRem(33);
    height: getRem(33);
    font-size: getRem(18);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
