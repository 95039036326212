@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


// paym-rep => payment-reports
.paym-rep {
    display: block;
    padding-bottom: getRem(80);

    &-hidden-block {
        visibility: hidden;
    }

    &-success {
        color: $contentSuccess;
    }

    &-accessory {
        color: $contentAccessory;
    }

    &-header {
        @extend .table-base-header-type-1;
        margin-bottom: getRem(40) !important;

        &-button {
            @extend .table-base-header-type-1-button;
            
            &-ext {
                @extend .paym-rep-header-button;

                display: flex;
                gap: getRem(24);
            }
        }
    }

    &-period {
        display: flex;
        align-items: center;
        column-gap: getRem(5);

        @include breakpoint(mobile_landscape) {
            column-gap: getRem(34);
        }

        &-wrapper {
            padding-left: getRem(32);
            padding-right: getRem(32);
            display: flex;
            flex-direction: column;
            gap: getRem(20);
            padding-top: getRem(20);

            @include breakpoint(mobile_landscape) {
                flex-direction: row;
                justify-content: space-between;
                padding-top: 0;
            }
        }

        &-project-filter {
            margin-left: getRem(80);
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;

            &-text {
                width: min-content !important;
                margin-right: getRem(16) !important;
                color: $contentAccessory !important;
    
                &:hover {
                    cursor: default !important;
                    font-size: getRem(14) !important;
                    font-weight: 400 !important;
                    text-decoration: none !important;
                }
            }

            .project-dropdown {
                margin-left: getRem(25);
            }
        }

        &-selected {
            text-decoration: underline $secondary;
        }

        &-generated {
            width: max-content !important;
            cursor: default !important;
            text-decoration: none !important;
        }

        &-checkbox {
            display: flex;
            height: getRem(16);

            .arrow {
                position: relative;
                bottom: getRem(-1);
                width: getRem(16) !important;
                height: getRem(16) !important;
                filter: grayscale(1);
            }
        }

        > span {
            color: $contentSupporting;
            
            &:first-child {
                padding-right: getRem(30);
                width: getRem(10);

                @include breakpoint(mobile_landscape) {
                    padding-right: getRem(6);
                }
            }

            &:nth-child(2) {
                margin-left: getRem(10);
            }

            &:not(:first-child) {
                width: getRem(80);
                padding: 0;
                &:hover {
                    @extend .t-s3;
                    cursor: pointer;
                    text-decoration: underline $secondary;
                }
            }
        }

        &-calendar {
            &:hover {
                @extend .t-s3;
                cursor: pointer;
                text-decoration: underline $secondary;
            }
        }

        .single-date-input-wrapper {
            margin: 0;
        }
    }

    &-rate {
        color: $contentLeading;

        .pen-icon {
            height: getRem(15);
            margin-left: getRem(6);
            position: relative;
            color: $secondary;
            top: getRem(-2);
            cursor: pointer;
        }
    }

    &-content {
        display: flex;
        justify-content: center;
    }

    &-wrapper-table {
        @extend .table-base;
        margin-top: getRem(70);

        tr {
            td {
                position: relative;
            }
        }
    }

    &-table {
        width: 100%;
        height: 100%;

        &-title {
            display: block;
            position: absolute;
            top: getRem(-70);
            opacity: 1 !important;

            &-employee {
                bottom: getRem(88);
                position: absolute;
            }

            &-ext {
                color: $contentAccessory;
            }
        }
        
        &-user {
            display: flex;
            align-items: center;

            &-checkbox {
                display: block;
                height: getRem(16);
            }

            &-img {
                display: block;
                img {
                    height: getRem(48);
                    width: getRem(48);
                    margin-right: getRem(16);
                    border-radius: getRem(4);
                }
            }
        }

        &-salary {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: flex-end;
            justify-content: flex-start;
            margin-left: getRem(-18);
            gap: getRem(6);

            svg {
                width: getRem(15) !important;
                height: getRem(15) !important;

                &:first-child {
                    color: $secondary;
                    opacity: 0.5;
                }
            }
        }

        &-client-label {
            color: $contentSupporting;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: getRem(101);
            display: inline-block;
            width: getRem(100) !important;

            span {
                display: contents !important;
            }
        }

        tr {
            display: flex;
            vertical-align: baseline;
            align-items: center;
            padding: getRem(15) 0;
            height: getRem(78);

            td {
                align-items: stretch;
                box-shadow: none;

                &:first-child {
                    min-width: getRem(250);
                    padding-left: getRem(8);
                    box-shadow: none;
                }

                &:last-child {
                    box-shadow: none;
                }
            }

            td:nth-child(2) {
                padding-left: getRem(17);
            }
        }
        
        &-date-row {
            td {
                border: none !important;
                
                &:nth-child(2) {
                    padding-left: 0 !important;
                }
            }
        }

        hr {
            background-color: $border;
            border: none;
            height: 1px;
            opacity: 0.2;
            margin-left: 0;
            margin: 0 getRem(36) 0 getRem(270)
        }

        &-total-row {
            align-items: flex-start !important;
            height: auto !important;
            td {
                div {
                    padding: 0 !important;
                }

                &:nth-child(7) {
                    text-align: right;
                    padding-right: getRem(16);
                }
            }
        }

        &-bonuses-row {
            align-items: flex-start !important;
            height: auto !important;
            padding-bottom: 0 !important;

            svg {
                width: getRem(15) !important;
                height: getRem(15) !important;
                color: $secondary;
                margin-right: getRem(8);
                margin-bottom: getRem(3);
                opacity: 0.5;
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            td {
                div {
                    padding: 0 !important;
                }

                &:nth-child(7) {
                    text-align: right;
                    padding-right: getRem(16);
                }
            }
        }

        &-ext {
            @extend .paym-rep-table;

            .paym-rep-table-user {
                padding-bottom: getRem(0);
            }

            tr:nth-child(2) {
                td {
                    padding-top: getRem(15);
                }
            }
        }

        &-empty {
            tr {
                box-shadow: none;
                background: inherit;
                height: 0 !important;
            }
        }
    }

    &-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: getRem(56);
        height: getRem(69);
        background: $background;
        width: 100%;
        padding-left: getRem(24);
        padding-right: getRem(24);
        position: fixed;
        bottom: getRem(0);
        box-shadow: $shadowReportSummary;
        z-index: 105;

        @include breakpoint(tablet) {
            width: calc(100% - getRem(70));
        }

        &-paid {
            display: flex;
            width: max-content;
            color: $contentSuccess;
        }

        &-need-to-pay {
            display: flex;
            color: $contentSupporting;
        }

        &-loader {
            display: block;
            width: 100%;
            padding-top: getRem(20);
            height: 100%;
        }
    }

    .changed-salary-info-icon {
        padding-bottom: getRem(3);
    }

    &-popup {
        &-content {
            text-align: center;
        }
    }
}

.salary-changes-popup {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: getRem(40);
    border-radius: getRem(8);
    margin-top: getRem(-10);
    overflow-y: auto;
    max-height: getRem(560) !important;
    width: 100%;
    margin-bottom: getRem(20);
    overflow-x: auto;

    @include breakpoint(desktop){
        width: getRem(636);
    }

    &-title {
        color: $contentLeading;
        text-align: center;
    }

    &-button {
        display: flex;
        height: getRem(33);
        padding: getRem(8) getRem(32);
        justify-content: center;
        align-items: center;
        gap: getRem(10);
        background-color: $secondary;
        color: $onSecondary;
        text-align: center;
        border: none;
        border-radius: getRem(8);

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
            background-color: $secondaryVariant;
        }

        &-close {
            position: absolute;
            top: getRem(23.5);
            right: getRem(24);
            width: getRem(32);
            height: getRem(32);
            color: $disabled;

            &:hover {
                color: $secondary;
                cursor: pointer;
            }
        }
    }

    &-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: getRem(32);
        width: 100%;
        align-items: stretch;

        &-column {
            &-overtime {
                text-transform: initial;
                position: absolute;
            }
        }

        &-row {
            color: $contentSupporting;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: getRem(20);
          
            td {
                width: 100%;
                min-width: getRem(100);
                &:first-child {
                    color: $contentSupporting;
                }
                &:nth-child(3) {
                    white-space: nowrap;
                }
                &:last-child {
                    justify-content: flex-end;
                    text-align: right;
                }

                .hours-overlogged-warning {
                    color: $contentError !important;
                }
            }
        }

        &-divider {
            margin-top: getRem(12);
            margin-bottom: getRem(12);
            color: $contentSupporting;
            opacity: 0.2;
        }
    }

    .heading-color {
        color: $contentLeading !important;
        display: flex;
        align-items: flex-start;
        gap: getRem(20);
        justify-content: space-between;

        th {
            width: 100%;
            &:last-child {
                text-align: end;
            }
        }
    }
}

.bonuses-popup {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: getRem(24);
    border-radius: getRem(8);
    margin-top: getRem(-10);
    overflow-y: auto;
    max-height: getRem(560) !important;
    width: 100%;
    overflow-x: auto;

    @include breakpoint(desktop){
        width: getRem(525);
    }

    &-title {
        color: $contentLeading;
        text-align: center;
    }

    &-button {
        display: flex;
        height: getRem(33);
        padding: getRem(8) getRem(32);
        justify-content: center;
        align-items: center;
        gap: getRem(10);
        background-color: $secondary;
        color: $onSecondary;
        text-align: center;
        border: none;
        border-radius: getRem(8);

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
            background-color: $secondaryVariant;
        }

        &-close {
            position: absolute;
            top: getRem(23.5);
            right: getRem(24);
            width: getRem(32);
            height: getRem(32);
            color: $disabled;

            &:hover {
                color: $secondary;
                cursor: pointer;
            }
        }
    }

    &-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: getRem(24);
        width: 100%;
        align-items: stretch;

        &-row {
            color: $contentSupporting;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: getRem(20);
          
            td {
                width: 100%;
                min-width: getRem(100);
                &:first-child {
                    color: $contentSupporting;
                }
                &:last-child {
                    justify-content: flex-start;
                }
            }
        }

        &-divider {
            margin-top: getRem(12);
            margin-bottom: getRem(12);
            color: $contentSupporting;
            opacity: 0.2;

            &-individual {
                margin-top: 0 !important;
            }
        }
    }

    .heading-color {
        color: $contentLeading !important;
        display: flex;
        align-items: flex-start;
        gap: getRem(20);
        justify-content: space-between;

        th {
            width: 100%;
            &:last-child {
                text-align: start;
            }
        }
    }
}
