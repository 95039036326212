@import '../../styles/index.scss';

.toolkit-dd-list {
  &-wrapper {
    margin-bottom: getRem(14);
  }

  &-label {
    margin: 0;
    padding-left: getRem(10);
    padding-bottom: getRem(4);
    color: $contentSupporting;
  }

  &-select {
    &__single-value {
      color: $contentSupporting !important;
    }

    &__input-container {
      padding: 0 !important;
      display: inline-flex;
    }

    &__value-container {
      z-index: 1;
      padding-left: getRem(13) !important;
      padding-right: getRem(13) !important;
      height: getRem(35) !important;
      width: max-content !important;
      overflow-y: hidden;
      overflow-x: visible;
      display: flex;
      align-items: center;
      flex-wrap: nowrap !important;
    }
    &__control {
      cursor: pointer;
      display: flex;
      align-items: center;
      min-height: auto !important;
      height: getRem(40) !important;
      width: auto !important;
      box-shadow: none !important;
      border: getRem(0.5) solid $disabled !important;
      border-radius: getRem(8) !important;
    }

    &__indicators {
      height: getRem(40) !important;
      width: getRem(40) !important;

      &-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrow {
        width: getRem(26);
        height: getRem(26);
        color: $secondary;
      }
    }

    &__indicator {
      color: $secondary !important;
      height: getRem(40) !important;
    }

    &__indicator-separator {
      margin: 7 !important;
      padding: 0 !important;
      background-color: $border !important;
    }

    &__dropdown-indicator {
      width: calc(getRem(39) - 1px);
      display: flex;
      justify-content: center;
      svg {
        height: getRem(40) !important;
        width: getRem(25) !important;
        transform: scale(0.8) !important;
      }
    }

    &__menu {
      border-radius: getRem(8) !important;
      box-shadow: $shadowDropdown !important;
      margin-top: 8 !important;
      overflow-y: auto !important;
      cursor: pointer !important;
      z-index: 5 !important;

      &::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
      }

      &::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
      }

      &::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
      }

      ::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
      }

      ::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
      }

      ::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
      }
    }

    &__option {
      cursor: pointer !important;
      @extend .t-s4;
      color: $contentSupporting !important;
      background-color: $background !important;
      display: flex;
      align-items: center;
      padding: getRem(8) getRem(12);
      border-radius: getRem(4);

      &--is-selected {
        @extend .t-s3;
        color: $contentHighlightPrimary !important;
      }

      .editable-option {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          border: none !important;
        }
      }

      .option-color {
        display: flex;
        padding: getRem(5) getRem(8);
        justify-content: center;
        align-items: center;
        color: $onSecondary;
        border-radius: getRem(3);
        gap: getRem(10);
      }

      .option-text {
        flex: 1;
      }

      .option-icons {
        display: flex;
        align-items: center;
        gap: getRem(8);
        visibility: hidden;

        .MuiSvgIcon-root {
          font-size: getRem(16);
          cursor: pointer;
          color: $disabled;

          &:hover {
            color: $secondaryVariant;
          }
        }
      }

      &:hover .option-icons {
        visibility: visible;
      }
    }
  }
}

.t-s4.toolkit-dd-list-select__multi-value {
  color: white !important;
  border-radius: getRem(8);
  padding: getRem(4) getRem(8);
  display: inline-flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  width: min-content !important;
  align-items: center;
  position: relative;
}

.t-s4.toolkit-dd-list-select__multi-value__label {
  color: white !important;
  padding: getRem(2) getRem(4);
  width: min-content !important;
  position: relative;
  overflow: visible;
}

.t-s4.toolkit-dd-list-select__multi-value__remove {
  cursor: pointer;
  padding: 0;
  &:hover {
    color: $secondaryVariant !important;
    background-color: transparent !important;
  }
}

.t-s4.toolkit-dd-list-select__clear-indicator {
  display: none;
}

.color-picker-popover {
  display: flex;
  gap: getRem(8);
  margin: getRem(10) getRem(16) getRem(16) getRem(16);

  &-text {
    margin-left: getRem(16);
    margin-top: getRem(16);
  }
}

.color-swatch {
  width: getRem(24);
  height: getRem(24);
  border-radius: 50%;
  cursor: pointer;
}
