@import '../../../../styles/index.scss';

.controls {
    height: getRem(40);
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: getRem(16);

    .MuiSlider{
        &-rail {
            opacity: 1;
        }

        &-track {
            color: $secondary;
        }
        
        &-root {
            color: $primary;
            height: getRem(2);
        }

        &-thumb {
            color: $secondary;
            width: getRem(15);
            height: getRem(15);
            margin-top: getRem(-7);
        }
    }
}

.cropper-container {
    position: relative;
    width: 100%;
    height: 300px;
}
