@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.projects {
  display: block;
  padding-bottom: getRem(48);

  &-header {
    @extend .table-base-header-type-1;

    &-button {
      @extend .table-base-header-type-1-button;
    }
  }

  &-unassign {
    &-items {  
      display: flex;
      flex-direction: column;
    }
    
    &-item {
      width: 100%;
      padding-left: getRem(10);
      padding-right: getRem(10);
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      min-height: getRem(32);

      &:hover {
        background-color: $backgroundControls;
      }
      span:nth-child(2) {
        display: block;
        margin-right: auto;
        padding-top: getRem(8);
        padding-bottom: getRem(8);
      }

      span:nth-child(3) {
        margin-left: auto;
        margin-right: getRem(40);
      }

      &-user-img {
        margin-left: getRem(16);
        margin-right: getRem(8);
        width: getRem(24);
        height: getRem(24);
        border-radius: 50%;
      }

      &-close {
        width: getRem(17);
        height: getRem(17);
        color: $contentAccessory;
      }
    }
  }

  &-content {
    display: flex;
    justify-content: center;

    &-table {
      @extend .table-base;

      &-first-column {
        padding-left: getRem(32);
      }

      &-team-column {
        padding-left: getRem(120);
        width: 60%;

        .cell-limited-text {
          width: getRem(400);
        }
      }

      &-last-column {
        width: getRem(280);

        &-ext {
          width: getRem(140);
        }
      }
    }
  }
}

.assign-project-popup {
  .rmdp-wrapper {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.unassign-projects-popup {
  .custom-popup-card-subtitle {
    margin-bottom: getRem(33);
  }
}