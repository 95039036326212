@import '../../styles/index.scss';


.notifications {

    &-footer {
        display: inline-block;
        width: 100%;
        text-align: center;
        padding-top: getRem(24);
        padding-bottom: getRem(22);
        color: $contentAccessory;
    }
    
    &-hide-content {
        width: getRem(550);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @include breakpoint(mobile) {
            width: getRem(200);
        }

        @include breakpoint(desktop) {
            width: getRem(550);
        }
    }
    
    &-show-content {
        overflow: visible;
        width: auto;
        white-space: normal;
    }
    

    .popup-content {
        margin: getRem(2) !important;
    }

    &-popup {
        max-height: getRem(433);
        height: auto;

        .custom-popup-card, .custom-popup-card-title, .custom-popup-card-content {
            margin: 0;
            padding: 0;
        }

        .custom-popup-card {
            position: fixed;
            max-width: getRem(813);
            max-height: getRem(433);
            min-height: getRem(140);
            height: auto;
            overflow-y: auto;
            top: getRem(88);
            left: 5vw;

            @include breakpoint(tablet) {
                left: calc(getRem(70) + 5vw);
                width: calc(90vw - getRem(70));
            }

            @include breakpoint(desktop) {
                left: auto;
                right: getRem(245);
            }

            &-wrapper {
                height: getRem(433);
                max-height: getRem(433);
            }
        }

        &-header {
            display: block;
            padding-top: getRem(23);
            margin-left: getRem(24);
            margin-right: getRem(24);
            padding-bottom: getRem(16);

            @include breakpoint(mobile_landscape) {
                margin-left: getRem(32);
                margin-right: getRem(32);
            }

            &-title {
                margin-right: getRem(16);
            }

            &-circle {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: getRem(24);
                height: getRem(24);
                background-color: $secondary;
                color: $onSecondary;
                border-radius: 100%;
                padding-top: getRem(2);

                @include breakpoint(mobile_landscape) {
                    width: getRem(32);
                    height: getRem(32);
                    padding-top: getRem(3);
                }
            }
        }

        ::-webkit-scrollbar {
            @extend .custom-scroll-webkit-scrollbar;
        }
          
        ::-webkit-scrollbar-track {
            @extend .custom-scroll-webkit-scrollbar-track;
        }
          
        ::-webkit-scrollbar-thumb {
            @extend .custom-scroll-webkit-scrollbar-thumb;
        }
    }

    &-table {
        display: block;
        width: getRem(813);
        width: auto;
        border-collapse: collapse;

        @include breakpoint(tablet_landscape) {
            width: auto;
        }

        td {
            padding-top: getRem(21);
            padding-bottom: getRem(21);
        }

        td:first-child, td:last-child {
            min-width: getRem(16);

            @include breakpoint(mobile_landscape) {
                min-width: getRem(32);
            }
        }

        td:nth-child(2) {
            border-bottom: 1px solid $surfaceDivider;
            width: 100%;
            height: getRem(62) !important;

            > div {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
        }

        &-row {
            min-height: getRem(62);
            height: getRem(62);

            &-wrapper {
                position: relative;

                > div:nth-child(1) {
                    width: getRem(20);
                    height: 100%;
                    margin: auto;
                    align-items: center;
        
                    @include breakpoint(mobile_landscape) {
                        width: getRem(48);
                    }
                }
                > div:nth-child(2) {
                    padding-right: getRem(5);
                    text-align: left;
                    width: getRem(200);
                    padding-top: getRem(2);
        
                    @include breakpoint(mobile_landscape) {
                        width: 100%;
                    }
                }
                > div:nth-child(3) {
                    width: getRem(80);
                    text-align: right;
        
                    @include breakpoint(mobile_landscape) {
                        width: getRem(140);
                    }
                }
            }

            &-shown {
                display: block;
                position: absolute;
                top: getRem(7.5);
                left: getRem(0);
                width: getRem(10);
                height: getRem(10);
                border-radius: 100%;
                background-color: $border;

                &-active {
                    @extend .notifications-table-row-shown;
                    background-color: $secondary;
                }
            }

            &-content {
             
                &-header {
                    color: $contentLeading;

                    &-alt {
                        color: $contentSupporting;
                    }
                }

                &-body {
                    @extend .notifications-hide-content;
                    margin-top: getRem(10);
                    color: $contentSupporting;
                    width: getRem(550);
                    text-align: left;
                    line-height: getRem(12);
                    letter-spacing: 0em;

                    &-alt {
                        @extend .notifications-hide-content;
                        margin-top: getRem(10);
                        color: $contentAccessory;
                    }
                }
            }

            &-date {
                color: $contentSupporting;

                &-alt {
                    color: $contentAccessory;
                }
            }
        }

        &-row:hover {
            background-color: $backgroundControls;

            td:first-child, td:last-child {
                border-top: 1px solid $surfaceDivider;
                border-bottom: 1px solid $surfaceDivider;
            }
        }

        &-row:first-child {
            td:nth-child(2) {
                border-top: 1px solid $surfaceDivider;
            }
        }

        &-empty {
            color: $contentAccessory;
            position: absolute;
            top: 45%;
            width: 100%;
            text-align: center;
        }
    }
}
