.clickable {
    cursor: pointer;
}

a {
    text-decoration: none;
    outline: none;
}

.custom-scroll-webkit-scrollbar {
    @include breakpoint(desktop) {
        width: getRem(14);
    }
}

.custom-scroll-webkit-scrollbar-track {
    border-left: solid getRem(0.5) $surfaceDivider;
  }

.custom-scroll-webkit-scrollbar-thumb {
    @include breakpoint(desktop) {
        background: $border;
        border-radius: getRem(100);
        border: getRem(4) solid transparent;
        background-color: $border;
        background-clip: content-box;
    }

    &:hover {
        @include breakpoint(desktop) {
            background-color: $vacationRequested;
        }
    }
}

html { // Apply the scrollbar styles to the whole HTML document
    scroll-behavior: auto !important;

    &::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar
    }

    &::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track
    }

    &::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb
    }
}

body {
    overflow-x: hidden;
    width: 100vw;
}
