@import '../../styles/index.scss';


.view-product {
  display: flex;
  padding-bottom: getRem(48);
  flex-direction: column;

  @include breakpoint(tablet_landscape) {
    flex-direction: row;
  }

  &-images {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin: getRem(24) getRem(16) getRem(0) getRem(16);
    gap: getRem(8);

    @include breakpoint(tablet_landscape) {
      gap: getRem(8);
      margin: getRem(24) getRem(58) getRem(0) getRem(40);
    }

    &-overlay {
      position: absolute;
      width: getRem(343) !important;
      height: getRem(356) !important;
      pointer-events: none;
      z-index: 1;

      @include breakpoint(mobile_landscape) {
        width: getRem(482) !important;
        height: getRem(500) !important;
      }

      &-labels {
        position: absolute;
        top: getRem(16);
        right: getRem(16);
        display: flex;
        gap: getRem(8);

        &-item {
          display: flex;
          height: getRem(18);
          border-radius: getRem(3);
          padding: getRem(4) getRem(8);
          justify-content: flex-end;
          align-items: center;
          color: $onSecondary;
        }
      }

      &-delivery-time {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-flex;
        padding: getRem(4) getRem(10);
        align-items: center;
        gap: getRem(4);
        border-radius: 0 getRem(16) 0 getRem(4);
        background: $primary;
        color: $onDisabled;

        svg {
          width: getRem(18) !important;
          height: getRem(18) !important;
          color: $onDisabled;
        }
      }
    }

    &-main {
      object-fit: cover;
      overflow: hidden;
      width: getRem(343) !important;
      height: getRem(356) !important;
      border-radius: getRem(8);

      @include breakpoint(mobile_landscape) {
        width: getRem(482) !important;
        height: getRem(500) !important;
      }
    }

    &-gallery {
      display: flex;
      height: getRem(117);
      width: getRem(343);
      align-items: flex-start;
      gap: getRem(17);
      overflow-x: scroll;
      overflow-y: hidden;
      padding-top: getRem(7);
      padding-left: getRem(6);
      padding-bottom: getRem(20);

      @include breakpoint(mobile_landscape) {
        width: getRem(482);
      }

      @include breakpoint(tablet_landscape) {
        gap: getRem(12);
      }

      @include breakpoint(desktop) {
        overflow: hidden;

        &:hover {
          overflow-y: hidden;
          overflow-x: scroll;
        }
      }

      &::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
      }

      &::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
      }

      &::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
      }

      .image-item {
        position: relative;
        overflow: visible;

        .image-selected-rectangle {
          position: absolute;
          width: getRem(116);
          height: getRem(116);
          border: getRem(2) solid $secondary;
          border-radius: getRem(12);
          left: getRem(-6);
          bottom: getRem(-6);
          z-index: 10;

          @include breakpoint(tablet) {
            width: getRem(96);
            height: getRem(96);
          }
        }
      }

      img {
        object-fit: cover;
        width: getRem(103) !important;
        height: getRem(103) !important;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: getRem(8);

        @include breakpoint(tablet) {
          width: getRem(84) !important;
          height: getRem(84) !important;
        }
      }
    }
  }

  &-content {
    margin-top: getRem(24);
    width: getRem(728);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: getRem(16);
    margin-right: getRem(16);

    @include breakpoint(tablet_landscape) {
      margin-left: getRem(0);
    }

    &-name {
      color: $contentLeading;
      margin-bottom: getRem(8);
      width: getRem(272);
      word-break: break-word;

      @include breakpoint(mobile_landscape) {
        width: auto;
      }
    }

    &-description {
      width: getRem(343);
      margin-bottom: getRem(19);
      color: $blackTransparent;
      font-size: getRem(16);
      font-weight: 400;
      line-height: 130%;

      @include breakpoint(mobile_landscape) {
          width: getRem(600);
      }

      @include breakpoint(tablet_landscape) {
        margin-bottom: getRem(40);
        width: 100%;
      }
    }

    &-status {
      color: $contentLeading;
      margin-bottom: getRem(16);

        .unavailable {
            color: $contentError;
        }
        .available {
            color: $contentSuccess;
        }
    }

    &-attributes {
      &-item {
        margin-bottom: getRem(16);
      }
    }

    &-options {
      &-item {
        margin-bottom: getRem(16);

        &-values {
          display: inline-flex;
          gap: getRem(8);
          height: getRem(46) !important;
          align-items: center;

          &-value {
            display: flex;
            width: max-content;
            padding: getRem(4) getRem(8);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: getRem(4);
            border: getRem(1) solid $disabled;
            color: $disabled;
            position: relative;

            &:hover {
              cursor: pointer;
              background: $secondary;
              color: $onSecondary;
              border-color: $secondary;
            }

            &.color-option {
              width: getRem(20) !important;
              height: getRem(20) !important;
              margin: getRem(3);
              border-radius: getRem(2);
              border: getRem(0.5) solid $disabled;
              border-color: $disabled !important;

              &:hover {
                border-color: $disabled !important;
              }
            }

            &.selected {
              background: $secondary;
              color: $onSecondary;
              border-color: $secondary;
            }

            &-tooltip {
              position: absolute;
              left: 50%;
              bottom: getRem(30);
              background-color: $primary;
              color: $onSecondary;
              box-shadow: 0 0 getRem(5) $logoShadow;
              border-radius: getRem(6);
              padding: getRem(6) getRem(6);
              font-size: getRem(8);
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
              transform: translateX(-50%);

              &::after {
                content: '';
                position: absolute;
                bottom: getRem(-4);
                left: 50%;
                transform: translateX(-50%);
                border-width: getRem(5) getRem(5) 0;
                border-style: solid;
                border-color: #333 transparent transparent transparent;
              }
            }

            &-border {
              width: max-content;
              height: max-content;
              border: getRem(1) solid $disabled;
              border-radius: getRem(4);

              &:hover {
                border: getRem(2) solid $secondary;
              }

              &.selected {
                border: getRem(2) solid $secondary;
              }
            }
          }
        }

        &-details {
          cursor: pointer;
          margin-left: getRem(24);
          color: $secondary;
        }
      }

      .not-available {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    &-price {
      margin-bottom: getRem(16);
      display: flex;
      align-items: center;
      gap: getRem(8);

      .active {
        color: $secondary;
      }

      .inactive {
        color: $disabled;
      }

      svg {
        width: getRem(36) !important;
        height: getRem(36) !important;
      }
    }

    &-buttons {
      width: getRem(343);
      display: flex;
      justify-content: space-between;

      @include breakpoint(mobile_landscape) {
        width: max-content;
        justify-content: left;
        gap: getRem(16);
      }

      button {
        height: getRem(33);
      }

      &-edit {
        position: absolute;
        cursor: pointer;
        right: getRem(40);
        top: getRem(100);
        color: $secondary;
      }
    }
  }
}

.view-product-details-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  gap: getRem(32);
  padding: 0 getRem(10);

  img {
    max-height: 70vh;
    border-radius: getRem(8);
    object-fit: contain;
    overflow: hidden;
    border: getRem(0.5) solid $border;

    @include breakpoint(mobile) {
      width: getRem(288);
    }

    @include breakpoint(mobile_landscape) {
      width: getRem(400);
    }

    @include breakpoint(tablet) {
      width: getRem(600);
    }

    @include breakpoint(desktop) {
      max-width: getRem(900);
    }
  }

  &-close {
    position: absolute;
    width: getRem(32) !important;
    height: getRem(32) !important;
    top: getRem(-16);
    right: getRem(-16);
    cursor: pointer;

    &:hover {
      color: $secondary;
    }

    @include breakpoint(mobile) {
      width: getRem(24) !important;
      height: getRem(23) !important;
      top: getRem(-21);
      right: getRem(-7);
    }

    @include breakpoint(tablet) {
      width: getRem(30) !important;
      height: getRem(30) !important;
      top: getRem(-15);
      right: getRem(-15);
    }
  }
}
