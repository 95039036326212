@import '../../styles/index.scss';


.toolkit-text-input {
    width: 100%;
    height: getRem(40);
    padding-left: getRem(15);
    padding-right: getRem(15);
    border: getRem(0.5) solid rgba(1, 33, 56, 0.5);
    border-radius: getRem(8);
    outline: none;
    color: $contentSupporting;

    &-wrapper {
        margin-bottom: getRem(14);
        max-height: getRem(60);

        > input:disabled {
            background-color: $surfaceDivider;
        }
    }

    &-label {
        margin: 0;
        padding-left: getRem(10);
        padding-bottom: getRem(4);
        color: $contentSupporting;
    }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}