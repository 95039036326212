@import '../../styles/index.scss';

.toolkit-slider-input {
  width: 100%;
  height: getRem(40);
  padding-left: getRem(15);
  padding-right: getRem(15);
  border: getRem(0.5) solid rgba(1, 33, 56, 0.5);
  border-radius: getRem(8);
  outline: none;
  cursor: default;
  color: $contentSupporting;

  &-wrapper {
    position: relative;
    margin-bottom: getRem(22);
    max-height: getRem(60);

    > input:disabled {
      background-color: $surfaceDivider;
    }
  }

  &-label {
    margin: 0;
    padding-left: getRem(10);
    padding-bottom: getRem(4);
    color: $contentSupporting;
  }
}

.slider{
  position: absolute;
  height: getRem(30) !important;
  width: getRem(48) !important;
  margin-bottom: getRem(10);
  margin-top: getRem(2);
  right: getRem(-3);
  top: getRem(27.5);
  color: $secondary;
  cursor: pointer;

  &-inactive {
    @extend .slider;
    color: $disabled;
  }
}

.slider-div {
  &::before {
    content: '';
    position: absolute;
    right: getRem(28);
    top: getRem(44);
    rotate: 90deg;
    border-top: getRem(0.4) solid rgba(1, 33, 56, 0.5);
    width: getRem(26);
  }

  &:hover {
    cursor: pointer;
  }
}

