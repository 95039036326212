@import '../../styles/index.scss';


.toolkit-slider {
    display: block;

    .MuiSwitch-switchBase {
        color: #E1E1E1;

        ~ .MuiSwitch-track {
            background-color: $border;
            opacity: 1 !important;
        }
    }

    .Mui-checked {
        color: #FED8B6 !important;
        
        ~ .MuiSwitch-track {
            background-color: $secondary !important;
            opacity: 1 !important;
        }
    }
}
