@import "../../styles/index.scss";

.text-editor {
    width: 100%;
    resize: none;
    outline: none;
    padding: 0;
    
    &-wrapper{
        padding: getRem(12) 0 getRem(12) getRem(16);
        border: getRem(0.5) solid rgba(1, 33, 56, 0.5);
        border-radius: getRem(8);
        color: $contentSupporting;
        position: relative;

        &-switcher {
            width: max-content;
            position: absolute;
            padding-left: getRem(8);
            padding-right: getRem(5);
            color: $contentAccessory;
            height: fit-content;
            right: getRem(15);
            border-left: 1px solid $contentAccessory;
            z-index: 2;
            
            span {
                cursor: pointer;
            }

            &.open {
                text-decoration-line: underline;
            }
        }

        li {
            &::marker {
                font-weight: 600 !important;
            }
        }
    }

    &-label {
        margin: 0;
        padding-left: getRem(10);
        padding-bottom: getRem(4);
        color: $contentSupporting;
    }

    &-link-component {
        width: min-content;
    }

    &-custom-link {
        align-items: center;
        border: $contentAccessory !important;
        border-left: 1px solid $contentAccessory !important;
        border-radius: inherit !important;
        color: $contentAccessory !important;
        text-align: center;
        padding: 0 getRem(8);
        display: flex;

        &:hover {
            cursor: pointer;
            color: #5F6C72 !important;
        }

        &.active {
            color: $onPrimary !important;
        }
    }

    &-popover {
        padding: getRem(8) getRem(16);
        display: flex;
        flex-direction: row;
        position: absolute;
        z-index: 1000;
        border-radius: getRem(4);
        border: getRem(0.5) solid $contentAccessory;
        background: $background;
        box-shadow: 0px 0px 10px 0px rgba(30, 30, 30, 0.10), 0px 6px 20px 0px rgba(30, 30, 30, 0.25);
        top: 15%;
        left: 30%;

        input {
            border: none;
            padding-right: getRem(8);
            width: getRem(300);
            color: $contentAccessory;
            @extend .t-s4;

            &:focus {
                outline: none;
                color: $contentAccessory;
                @extend .t-s4;
            }
        }

        &-confirm {
            padding-left: getRem(8);
            border-left: getRem(0.5) solid $textEditorLink;
            svg {
                color: $textEditorLink;
                width: getRem(28);
                height: getRem(28);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    ::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
    }
      
    ::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
    }
      
    ::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
    }
}