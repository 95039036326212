@import '../../styles/index.scss';


.color-picker {

  &-swatch {
    position: absolute;
    right: getRem(10);
    bottom: getRem(27);
    width: getRem(15) !important;
    padding: getRem(1);
    background: $background;
    border-radius: getRem(1);
    box-shadow: 0 0 0 getRem(1) rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
    z-index: 2;
  }

  &-color {
    width: getRem(13) !important;
    height: getRem(14) !important;
    border-radius: getRem(2);

    &-popover {
      bottom: getRem(-80);
      right: getRem(-500);
      position: absolute;
      z-index: 3;

      &-cover {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}
